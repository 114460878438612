<script setup>
  import { onBeforeMount, ref, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useI18n } from 'vue-i18n';

  import {
    Dropdown as ADropdown,
    Menu as AMenu,
    Modal as AModal,
    Alert as AAlert,
    TypographyLink as ATypographyLink,
  } from 'ant-design-vue';
  import 'ant-design-vue/es/dropdown/style/index.js';
  import 'ant-design-vue/es/menu/style/index.js';
  import 'ant-design-vue/es/modal/style/index.js';
  import 'ant-design-vue/es/alert/style/index.js';
  import 'ant-design-vue/es/typography/style/index.js';
  import {
    DownOutlined,
    UserOutlined,
    GlobalOutlined,
  } from '@ant-design/icons-vue';

  import LayoutLogoShinwellTop from '@/layout/components/layout-logo-shinwell-top.vue';

  import LayoutMenuTop from '@/layout/components/layout-menu-top.vue';

  import useLocale from '@/compositions/use-locale.js';

  import { getAccessToken } from '@/utils/accessToken';
  import { isEmpty } from '@/utils/utils-validator';

  import { I18nLanguage } from '@/locales/locales.js';

  import { useUserStore } from '@/stores/user.js';

  import { fetchSystemSettingOfNotificationWithEffective } from '@/api/system-setting';
  import AiChatBubble from '@/components/ai/ai-chat-bubble.vue';

  defineOptions({
    name: 'LayoutBasicRouter',
  });

  const router = useRouter();
  const userStore = useUserStore();
  const { t } = useI18n();

  const { currentLocale, setLocaleToZhCN, setLocaleToEnUS } = useLocale();

  //登出
  const logout = () => {
    // 清除token
    userStore.removeAccessToken();
    router.replace({
      name: 'LoginRouter',
    });
  };

  // 跳转至账号管理页面
  const toAccountManage = () => {
    router.push({
      name: 'AccountManage',
    });
  };

  /**
   * 点击切换简体中文按钮回调钩子
   */
  const onClickToSetLocaleToZhCN = () => {
    if (currentLocale.value === I18nLanguage.ZH_CN) {
      return;
    }

    AModal.confirm({
      title: t('app.common.notification'),
      content: t('app.compositions.confirmSetLocale', {
        language: t('app.common.simplifiedChinese'),
      }),
      onOk: () => {
        setLocaleToZhCN();
        location.reload();
      },
    });
  };

  /**
   * 点击切换英文按钮回调钩子
   */
  const onClickToSetLocaleToEnUS = () => {
    if (currentLocale.value === I18nLanguage.EN_US) {
      return;
    }

    AModal.confirm({
      title: t('app.common.notification'),
      content: t('app.compositions.confirmSetLocale', {
        language: t('app.common.english'),
      }),
      onOk: () => {
        setLocaleToEnUS();
        location.reload();
      },
    });
  };

  /**
   * 系统通知公告逻辑块
   **/
  const systemNotification = ref({
    content: '', // 通知内容
    link: '', // 链接
  });
  /**
   * 系统通知公告内容是否存在
   */
  const hasSystemNotificationContent = computed(
    () => !isEmpty(systemNotification.value.content),
  );
  /**
   * 链接地址是否存在
   */
  const hasSystemNotificationLink = computed(
    () => !isEmpty(systemNotification.value.link),
  );
  /**
   * 初始化系统通知公告信息
   * @param {Object} result promiseAllSettled 执行结束对象
   * @param {string} result.status 执行结果
   * @param {*} result.value 执行成功返回结果
   * @param {Error} result.reason 执行失败错误信息
   */
  function initSystemSettingOfNotificationWithEffective(result) {
    if (result.status === 'rejected') {
      console.error(
        '[initSystemSettingOfNotificationWithEffective]:',
        result.reason,
      );
    }

    if (isEmpty(result.value)) {
      return;
    }

    for (const key in systemNotification.value) {
      if (isEmpty(result.value[key])) continue;
      systemNotification.value[key] = result.value[key];
    }
  }

  /**
   * 渲染组件
   */
  function setupComponent() {
    Promise.allSettled([fetchSystemSettingOfNotificationWithEffective()]).then(
      ([resultFetchSystemSettingOfNotificationWithEffective]) => {
        initSystemSettingOfNotificationWithEffective(
          resultFetchSystemSettingOfNotificationWithEffective,
        );
      },
    );
  }

  setupComponent();

  onBeforeMount(() => {
    //  获取菜单
    userStore.updateMenusAndUserInfo();
    // 有token时获取用户信息
    if (getAccessToken()) {
      userStore.getUserInfo();
    }
  });
</script>
<template>
  <section class="app-layout-container">
    <header class="app-layout-header">
      <div class="app-layout-header--start">
        <LayoutLogoShinwellTop />

        <LayoutMenuTop />
      </div>

      <div class="app-layout-header-end">
        <ADropdown placement="bottomLeft">
          <template #default>
            <span class="app-dropdown">
              <GlobalOutlined style="font-size: 16px" />
            </span>
          </template>

          <template #overlay>
            <AMenu
              :selected-keys="[currentLocale]"
              selectable
            >
              <AMenu.Item
                :key="I18nLanguage.ZH_CN"
                @click="onClickToSetLocaleToZhCN"
              >
                简体中文
              </AMenu.Item>

              <AMenu.Item
                :key="I18nLanguage.EN_US"
                @click="onClickToSetLocaleToEnUS"
              >
                English
              </AMenu.Item>
            </AMenu>
          </template>
        </ADropdown>

        <ADropdown placement="bottom">
          <template #default>
            <div class="app-dropdown">
              <UserOutlined style="margin-right: 4px" />
              <span>{{ userStore.userInfo?.fullName }}</span>
              <span>（{{ userStore.userInfo?.username }}）</span>
              <DownOutlined />
            </div>
          </template>

          <template #overlay>
            <AMenu :selected-keys="[]">
              <AMenu.Item
                key="account"
                @click="toAccountManage"
              >
                {{ t('app.common.userManage') }}
              </AMenu.Item>
              <AMenu.Item
                key="logout"
                @click="logout"
              >
                {{ t('app.common.logout') }}
              </AMenu.Item>
            </AMenu>
          </template>
        </ADropdown>
      </div>
    </header>

    <main class="app-layout-main">
      <AAlert
        v-if="hasSystemNotificationContent"
        style="margin-bottom: 16px"
        type="info"
        show-icon
      >
        <template #message>
          <span>{{ systemNotification.content }}</span>
          <ATypographyLink
            v-if="hasSystemNotificationLink"
            style="margin-left: 8px"
            :href="systemNotification.link"
            target="_blank"
          >
            {{ '查看详情 >' }}
          </ATypographyLink>
        </template>
      </AAlert>

      <section
        :class="
          hasSystemNotificationContent
            ? 'app-layout-main--split'
            : 'app-layout-main--full'
        "
      >
        <router-view v-slot="{ Component }">
          <transition
            mode="out-in"
            name="fade-transform"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </section>
    </main>
  </section>
  <AiChatBubble />
</template>

<style lang="less" scoped>
  .app-layout-container {
    min-width: 1200px;
    height: 100vh;

    .app-layout-header {
      height: 64px;
      display: flex;
      justify-content: space-between;
      .app-layout-header--start,
      .app-layout-header-end {
        display: flex;
      }

      .app-layout-header-end {
        padding-right: 20px;
      }
    }

    .app-layout-main {
      height: calc(100vh - 64px);
      box-sizing: border-box;
      padding: 20px;
      background-color: #fafafa;

      .app-layout-main--split {
        height: calc(100% - 40px - 16px);
      }

      .app-layout-main--full {
        height: 100%;
      }
    }
  }

  .app-dropdown {
    display: flex;
    align-items: center;

    padding: 0 12px;

    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.025);
    }
  }
</style>
