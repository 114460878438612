<script setup>
  import { computed } from 'vue';

  import { getStaticSvgFileUrl } from '@/utils/utils-static-file.js';

  import SettingsIconName from '@/settings/settings-icon-name.js';

  defineOptions({
    name: 'AppLogoShinwellMinor',
  });

  /**
   * 星威养车图标地址
   */
  const logoShinwellUrl = computed(() =>
    getStaticSvgFileUrl(SettingsIconName.appLogoMinor),
  );
</script>

<template>
  <img
    :src="logoShinwellUrl"
    alt="logo-shinwell"
    width="52"
    height="42"
  />
</template>
