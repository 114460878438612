export default {
  // 报修类型
  'maint.constants.option.MaintRequestType.EMERGENCY_RESCUE': '紧急救援',
  'maint.constants.option.MaintRequestType.MAINT_RESERVATION': '预约维保',
  'maint.constants.option.MaintRequestType.BATCH_IMPORT': '批量维保',
  'maint.constants.option.MaintRequestType.STATION_BATCH_MAINT': '批量维保',

  // 保外确认状态
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_REVIEW': '待审核',
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_CONFIRM': '待确认',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED_NOT_UPLOADED_VOUCHER': '已确认缺凭证',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED': '已确认',
  'maint.constants.option.MaintUncoveredQuotationStatus.REJECTED': '已驳回',
  'maint.constants.option.MaintUncoveredQuotationStatus.REVOKED': '已作废',
}