export default {
  'user.components.passwordTips': '（不更改密码此项不填）',
  'user.components.usernameRegExp': '英文和数字的组合，长度在7～16位之间',
  'user.components.locations': '业务地区',
  'user.components.basicInformation': '基础信息',
  'user.components.assigningRoles': '分配角色',
  'user.components.pleaseEnterRoleName': '请输入角色名称',
  'user.components.functionName': '功能名称',
  'user.components.pleaseEnterFunctionName': '请输入功能名称',
  'user.components.selectedRole': '已选中角色',
  'user.components.viewPermission': '查看权限',
  'user.components.distributionVehicleGroup': '分配车队小组',
  'user.components.fleetTeam': '车队小组',
  'user.components.AssignUserTeamLeader':
    '将该用户分配至对应车队小组当车队长（可多选）',
};
