export default {
  'user.manage.details.templateUserGroups': '',
  'user.manage.details.belonging': 'Affiliated',
  'user.manage.details.locked': 'Locked',
  'user.manage.details.unlock': 'Unlocked',
  'user.manage.details.normal': 'Normal',
  'user.manage.details.dutyDate': 'Duty Date',
  'user.manage.details.dutyStatus': 'Duty Status',
  'user.manage.details.failedReason': 'Failure Reason',
  'user.manage.details.schedulingTable': 'Scheduler',
  'user.manage.details.schedule': 'Scheduling',
  'user.manage.details.exportRemark':
    'Note: &#10;1, red fields are required. 2、Mobile phone number should be the 11-digit number that exists in the system. 3、Duty date should be entered in the format of "2020/1/1" or "2020-1-1".',
  'user.manage.details.importFailedName':
    'Failed to import data for scheduling table',
  'user.manage.details.lockTips':
    'Account has been locked, unable to log in the system',
  'user.manage.details.basicSetting': 'Basic Settings',
  'user.manage.details.switchboardNumber': 'Extension number',
  'user.manage.details.maintApprovalSetting':
    'Maintenance Order Approval Settings',
  'user.manage.details.assignedVehicleTeam': 'Specify fleet',
  'user.manage.details.searchVehicleTeam': 'Fleet Search',
  'user.manage.details.accountRole': 'Account Roles',
  'user.manage.details.assignedRole': 'Assigned Roles',
  'user.manage.details.accountPermissions': 'Account Privileges',
};
