import router from '@/router/index.js';

import { routesWhiteList } from '@/config';

import getPageTitle from '@/utils/pageTitle';
import { getAccessToken } from '@/utils/accessToken';
import { isEmpty } from '@/utils/utils-validator.js';

import { useUserStore } from '@/stores/user.js';

/**
 * 路由导航守卫
 */
router.beforeEach(async (to, _from, next) => {
  // 当前将要访问的路径在免登录名单中，直接放行
  if (routesWhiteList.indexOf(to.path) !== -1) {
    next();
  }

  // 从浏览器 storage 中获取 token 登陆令牌
  const token = getAccessToken();
  // 若存在 token 则进行权限校验
  if (token) {
    // TODO: 校验 token 是否过期，若已过期则直接进入登录页面。

    // 获取跳转路由所需要的权限
    const toRoutePermissionKey = to.meta.permissionKeyWithRoute?.key;
    // 若路由所需要的权限不存在，代表此路由为静态路由，直接放行。
    if (!toRoutePermissionKey) {
      next();
    }

    const userStore = useUserStore();
    // 获取权限列表
    let userPermissionKeys = userStore.permissionKeys;
    // 若权限列表不存在，则重新向后端发起请求获取最新权限列表，并存储。
    if (isEmpty(userPermissionKeys)) {
      userPermissionKeys = await userStore.getPermissionKeys();
    }

    // 验证访问路径对应的权限在用户的权限列表内是否拥有，若拥有，则放行通过。
    // 反之，拦截并进入无权限引导页。
    userPermissionKeys.includes(toRoutePermissionKey)
      ? next()
      : next({ path: '/403', replace: true });
  }
  // 若不存在 token 则跳转至登陆页面
  else {
    next({ path: '/login', query: { redirect: to.fullPath } });
  }
});

router.afterEach((to) => {
  document.title = getPageTitle(to.meta.title);
});
