export default {
  'vehicleTeamMonthlyInspectionReport.common.viewReport': '查看报告',
  'vehicleTeamMonthlyInspectionReport.common.forMoreItems':
    '更多内容请去电脑端查看',
  'vehicleTeamMonthlyInspectionReport.common.inspectionItems': '主要检查项目',
  'vehicleTeamMonthlyInspectionReport.common.inspectionAbnormalStatistics':
    '检查异常统计',
  'vehicleTeamMonthlyInspectionReport.common.detailsOfInspectionAbnormals':
    '检车异常明细',
};
