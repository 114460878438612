<script setup>
  import { onMounted, ref } from 'vue';
  import { useUserStore } from '@/stores/user';
  import { getAiChatConfig } from '@/api/ai-chat';

  const isAssistantVisible = ref(false);
  const isDragging = ref(false);
  const toggleAssistant = () => {
    if (!isDragging.value) {
      isAssistantVisible.value = !isAssistantVisible.value;
    }
  };
  const userStore = useUserStore();
  const userInfo = userStore.getUserInfo();

  const assistantConfig = ref({});
  onMounted(async () => {
    const response = await getAiChatConfig();
    assistantConfig.value = response.data.assistant;
  });

  const bubbleStyle = ref({
    position: 'fixed',
    bottom: '120px',
    right: '60px',
    width: '60px',
    height: '60px',
    cursor: 'pointer',
    zIndex: 1000,
  });

  let offset = { x: 0, y: 0 };

  const onPointerDown = (event) => {
    isDragging.value = false;
    offset = {
      x: event.clientX - event.target.getBoundingClientRect().left,
      y: event.clientY - event.target.getBoundingClientRect().top,
    };
    event.target.setPointerCapture(event.pointerId);
    event.preventDefault(); // Prevent default behavior like scrolling
    window.addEventListener('pointermove', onPointerMove, { passive: false });
    window.addEventListener('pointerup', onPointerUp);
  };

  const onPointerMove = (event) => {
    if (event.buttons === 1) {
      // Check if the primary mouse button is pressed
      isDragging.value = true;
      bubbleStyle.value = {
        ...bubbleStyle.value,
        left: `${event.clientX - offset.x}px`,
        top: `${event.clientY - offset.y}px`,
        bottom: 'auto',
        right: 'auto',
      };
      event.preventDefault(); // Prevent any default behavior during dragging
    }
  };

  const onPointerUp = (event) => {
    window.removeEventListener('pointermove', onPointerMove);
    window.removeEventListener('pointerup', onPointerUp);
    event.target.releasePointerCapture(event.pointerId);
    setTimeout(() => {
      isDragging.value = false;
    }, 0);
  };

  onMounted(() => {
    window.addEventListener('pointerup', onPointerUp); // Ensure pointerup is always handled
  });
</script>

<template>
  <div>
    <div
      id="ai-assistant-bubble"
      :style="bubbleStyle"
      @click="toggleAssistant"
      @pointerdown="onPointerDown"
    >
      <img
        :src="assistantConfig.avatar"
        alt="AI Assistant"
        width="68"
        height="68"
      />
    </div>
    <div
      v-show="isAssistantVisible"
      id="ai-assistant-container"
    >
      <a-drawer
        :visible="isAssistantVisible"
        size="large"
        :title="assistantConfig.title"
        placement="right"
        @close="isAssistantVisible = false"
      >
        <iframe
          width="100%"
          height="100%"
          :src="`${assistantConfig.BASE_URL}&id=${userInfo.mobileNumber}`"
        />
      </a-drawer>
    </div>
  </div>
</template>

<style scoped>
  #ai-assistant-bubble {
    position: fixed;
    bottom: 120px;
    right: 60px;
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 1000;
  }

  #ai-assistant-container {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 400px;
    height: 600px;
    z-index: 1000;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
</style>
