export default {
  'directSupplyManage.common.title': '直供件管理',
  'directSupplyManage.common.fittingsAliasName': '配件名称/别名',
  'directSupplyManage.common.category': '品类',
  'directSupplyManage.common.brand': '品牌',
  'directSupplyManage.common.model': '型号',
  'directSupplyManage.common.viewDetails': '查看明细',
  'directSupplyManage.common.inventoryQuantityChangeDetails':
    '库存数量变化明细',
  'directSupplyManage.common.close': '关闭',
  'directSupplyManage.common.fittingsName': '配件名称',
  'directSupplyManage.common.description': '说明',
  'directSupplyManage.common.inventory': '库存',
  'directSupplyManage.common.unit': '单位',
  'directSupplyManage.common.operate': '操作',
  'directSupplyManage.common.changeType': '变化类型',
  'directSupplyManage.common.count': '数量',
  'directSupplyManage.common.unitPrice': '单价({unit})',
  'directSupplyManage.common.date': '日期',
  'directSupplyManage.common.status': '状态',
  'directSupplyManage.common.remark': '备注',
  'directSupplyManage.common.totalPrice': '总价({unit})',
  'directSupplyManage.common.yes': '是',
  'directSupplyManage.common.no': '否',
};
