export default {
  'directSupplyManage.common.title': 'Fitting',
  'directSupplyManage.common.fittingsAliasName': 'Accessories Name/Alias',
  'directSupplyManage.common.category': 'Category',
  'directSupplyManage.common.brand': 'Brand',
  'directSupplyManage.common.model': 'Model',
  'directSupplyManage.common.viewDetails': 'View Details',
  'directSupplyManage.common.inventoryQuantityChangeDetails':
    'Inventory Quantity Change Details',
  'directSupplyManage.common.close': 'Close',
  'directSupplyManage.common.fittingsName': 'Accessory Name',
  'directSupplyManage.common.description': 'Description',
  'directSupplyManage.common.inventory': 'Inventory',
  'directSupplyManage.common.unit': 'Unit',
  'directSupplyManage.common.operate': 'Operation',
  'directSupplyManage.common.changeType': 'Change Type',
  'directSupplyManage.common.count': 'Quantity',
  'directSupplyManage.common.unitPrice': 'Unit Price ({unit})',
  'directSupplyManage.common.date': 'Date',
  'directSupplyManage.common.status': 'Status',
  'directSupplyManage.common.remark': 'Remarks',
  'directSupplyManage.common.totalPrice': 'Total Price ({unit})',
  'directSupplyManage.common.yes': 'Yes',
  'directSupplyManage.common.no': 'No',
};
