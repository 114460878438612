/**
 * 3个子配置，通用配置|网络配置，建议在当前目录下修改config.js修改配置，会覆盖默认配置，也可以直接修改默认配置
 */
// 默认配置
import { setting, network } from './default/index';

// 导出配置
export const settingObj = Object.assign({}, setting, network);

export const {
  storage,
  tokenTableName,
  contentType,
  requestTimeout,
  successCode,
  tokenName,
  messageDuration,
  title,
  titleReverse,
  titleSeparator,
  routesWhiteList,
  appId,
} = settingObj;

export default settingObj;
