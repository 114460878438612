export default {
  'app.common.templatePlaceholder': 'Please enter your {label}',
  'app.common.templateCharacterLength': '{label} must be {len} characters',
  'app.common.templateCharacterMin':
    '{label} must be at least {min} characters',
  'app.common.templateCharacterMax': '{label} must be up to {max} characters',
  'app.common.templateCharacterRange':
    '{label} must be between {min}-{max} characters',
  'app.common.templateSelectPlaceholder': 'Please select {label}',
  'app.common.templateHasSave': '{column}saved',
  'app.common.templateTotal': 'Total {total} items',
  'app.common.templateType': '{label} must be {type}',
  'app.common.templatePictures': '{total} pictures',
  'app.common.templateVideos': '{total} videos',

  'app.common.shinwell': 'SHINING WAY',
  'app.common.colon': ':',

  'app.common.simplifiedChinese': 'Simplified Chinese',
  'app.common.english': 'English',

  'app.common.number': 'number',

  'app.common.notification': 'Notification',
  'app.common.save': 'Save',
  'app.common.confirm': 'Confirm',
  'app.common.confirmWithSpace': 'Confirm',
  'app.common.cancel': 'Cancel',
  'app.common.success': 'Success',
  'app.common.resetWithSpace': 'Reset',
  'app.common.iSee': 'I see',
  'app.common.tips': 'Tips',

  'app.common.username': 'Username',
  'app.common.password': 'Password',
  'app.common.newPassword': 'New password',
  'app.common.oldPassword': 'Old password',
  'app.common.forgotPassword': 'Forgot password',
  'app.common.mobileNumber': 'Mobile number',
  'app.common.callingCode': 'Celling code',
  'app.common.sms': 'Verification',
  'app.common.sendSms': 'Get verification code',
  'app.common.status': 'Status',
  'app.common.ok': 'Confirm',
  'app.common.fullName': 'Name',
  'app.common.email': 'Email',
  'app.common.add': 'Add',
  'app.common.import': 'Import',
  'app.common.account': 'Account',
  'app.common.set': 'Setting',
  'app.common.noPermission': 'No permission',
  'app.common.user': 'Users',
  'app.common.operate': 'Action',
  'app.common.remove': 'Remove',
  'app.common.edit': 'Edit',
  'app.common.delete': 'Delete',
  'app.common.okText': 'Determine',
  'app.common.enable': 'Enable',
  'app.common.disable': 'Disable',
  'app.common.confirmToDelete': 'Are you sure about deletion?',
  'app.common.snapshot': 'Logs',
  'app.common.basic': 'Basic',
  'app.common.available': 'Available',
  'app.common.basicInformation': 'Basic information',
  'app.common.contactPerson': 'Contact person',
  'app.common.china': 'China',
  'app.common.malaysia': 'Malaysia',
  'app.common.philippines': 'Philippines',
  'app.common.export': 'Export',
  'app.common.exporting': 'Exporting',
  'app.common.contactMobile': 'Phone Number',
  'app.common.contractedStation': 'Contracted Station',
  'app.common.cooperativeStation': 'Cooperative Station',
  'app.common.temporaryStation': 'Temporary Station',
  'app.common.multipleChoice': 'Multi-select',
  'app.common.brand': 'Brand',
  'app.common.fitting': 'Accessory',
  'app.components.cityArea': 'Area',
  'app.components.vehiclePlateNo': 'Plate No.',
  'app.components.vehicleTeamName': 'Fleet Group Name',
  'app.components.allVehicle': 'All Vehicles',

  'app.common.browsersNotSupportTag':
    'Your browser does not support the {tag} tag',

  'app.common.time': 'Time',
  'app.common.operator': 'Operator',
  'app.common.operation': 'Operation',
  'app.common.remark': 'Remark',
  'app.common.operateType': 'Operation Type',
  'app.common.startTime': 'Start time',
  'app.common.endTime': 'End time',
  'app.common.menu': 'Menu',
  'app.common.maint': 'Maintenance',
  'app.common.dispatch': 'Dispatch',
  'app.common.additionalDispatch': 'Supplementary Dispatch',
  'app.common.maintOrderTransfer': 'Transfer',
  'app.common.maintOrderStationChange': 'Replace station',
  'app.common.maintUncoveredCostInformSubmit':
    'Initiate non-covered expense confirmation',
  'app.common.maintOrderRecordDelegate': 'Record order on behalf',
  'app.common.maintOrderQuoteAudit': 'Approve Quote',
  'app.common.maintOrderRevoke': 'cancel',
  'app.common.maintOrderViewApprovedUser': 'View Approved Quoter Information',
  'app.common.plateNo': 'Plate No.',
  'app.common.maintOrderReplaceAuditor': 'Replace Approver',
  'app.common.maintRequestImport': 'Batch Maintenance',
  'app.common.maintOrderRecheck': 'Review',
  'app.common.vehicleMileage': 'Mileage',
  'app.common.vehicleMileageExport': 'Mileage Export',
  'app.common.vehicleMileageMonthlyConfirm': 'Monthly Mileage Confirmation',
  'app.common.vehicleMileageCalibration': 'Manual Calibration',
  'app.common.vehicleMileageStatement': 'Mileage Bill',
  'app.common.vehicleMileageMonthlyReconcile': 'Monthly Reconciliation',
  'app.common.maintPlan': 'Maint Plan',
  'app.common.distributionCenterFittingClaimOrder': 'Fit Order',
  'app.common.billing': 'Billing',
  'app.common.finance': 'Finance',
  'app.common.stationAmercementBatchCreate':
    'Import Station Amercements (Finance Only)',
  'app.common.stationAmercementDetail': 'Fine Details',
  'app.common.stationMonthlyStatement': 'Statement',
  'app.common.stationMonthlyStatementPlatformConfirm': 'Statement Confirmation',
  'app.common.stationMonthlyStatementRegenerate':
    'Void and regenerate Statement',
  'app.common.stationMonthlyStatementSettle': 'Statement Settlement',
  'app.common.stationMonthlyStatementPlatformExport': 'Statement Export',
  'app.common.stationMonthlyStatementFeeImport': 'Statement Charge Import',
  'app.common.stationMonthlyStatementCommitInvoice':
    'Statement Voucher Submission',
  'app.common.stationMonthlyStatementConfirmDownload':
    'Statement Confirmation Download',
  'app.common.maintSettlementOrder': 'Billing',
  'app.common.externalBilling': 'External Billing',
  'app.common.maintUncoveredBilling': 'Maint UnCovered Billing',
  'app.common.maintUncoveredBillingImportReconciliation':
    'Import Reconciliation Status',
  'app.common.maintUncoveredBillingImportSettlement':
    'Import Settlement Status',
  'app.common.maintUncoveredBillingExportDetail': 'Detail Export',
  'app.common.importMaintSettlementInfo': 'Import Settlement Status',
  'app.common.exportMaintSettlementOrder': 'Detail Export',
  'app.common.importPlatformExternalBilling':
    'Platform External Billing Import',
  'app.common.exportPlatformExternalBillingOrder': 'Platform Detail Export',
  'app.common.exportStationExternalBillingOrder': 'Station Detail Export',
  'app.common.property': 'Assets',
  'app.common.vehicleManage': 'Vehicles',
  'app.common.vehicleImport': 'Import license information',
  'app.common.vehicleServiceInfoImport': 'Import Service Information',
  'app.common.vehicleDriverImport': 'Import Driver Information',
  'app.common.vehicleDriverMaintain': 'Maintain Vehicle Drivers',
  'app.common.vehicleLicenseEdit': 'Maintain Vehicle License',
  'app.common.vehicleDetailImport': 'Import Details',
  'app.common.details': 'Detailed Information',
  'app.common.vehicleEffectiveAndIneffective':
    'Marking Invalid Vehicles and Recovering Vehicles',
  'app.common.vehicleTeamGroupImport': 'Updating Teams',
  'app.common.vehicleLicImgImport':
    'Upload the Vehicle Registration of the Team',
  'app.common.vehicleLicenseAdd': 'Add Vehicle',
  'app.common.fleetOwnershipEdit': 'Update Fleet Ownership',
  'app.common.vehicleMaintCoverHistoryEdit': 'Batch update coverage records',
  'app.common.vehicleTeamManage': 'Fleet',
  'app.common.vehicleTeamOperate': 'Fleet Operation Privileges',
  'app.common.vehicleTeamGroupAssociated': 'Affiliated Vehicles',
  'app.common.vehicleTeamMaintUncoveredPipOperate': 'Quotation Increase Ratio',
  'app.common.vehicleTeamGroupEdit': 'Fleet Groups',
  'app.common.vehicleGroupStationMaintUncoveredPipImport':
    'Import Non-covered Increase Ratio',
  'app.common.vehicleGroupStationMaintUncoveredPipExport':
    'Export Non-covered Increase Ratio',
  'app.common.vehicleTeamMaintUncoveredOfferPriceImport':
    'Import Non-covered agreement price',
  'app.common.vehicleTeamMaintUncoveredOfferPriceExport':
    'Export Non-covered agreement price',
  'app.common.serviceStationManage': 'Station',
  'app.common.serviceStationViewAll': 'View all service station permissions',
  'app.common.stationDsfittingInventoryOperate': 'Fitting Inventory',
  'app.common.stationAmercementView': 'View Fines',
  'app.common.serviceStationOperate': 'Other Station Privileges',
  'app.common.stationConfirmReceivedMarginPayment':
    'Deposit Collection Confirmation',
  'app.common.stationSignContractConfirm': 'Signing Confirmation',
  'app.common.stationSuspendResumeDispatch': 'Suspend and resume orders',
  'app.common.stationWorkinghourPriceOperate':
    'Operating privileges of labor hour price',
  'app.common.stationFittingPriceOperate':
    'Accessory price operation privilege',
  'app.common.distributionCenterManage': 'Distribution',
  'app.common.distributionCenterOperate': 'Distribution operation',
  'app.common.data': 'Data',
  'app.common.workinghourManage': 'Workinghour',
  'app.common.workinghourManageEdit': 'Workinghour Operation Privileges',
  'app.common.fittingManage': 'Fitting',
  'app.common.fittingManageEdit': 'Fitting Operation Privileges',
  'app.common.fittingModelManage': 'Model',
  'app.common.fittingModelManageEdit': 'Model Operation Privileges',
  'app.common.faultManage': 'Fault',
  'app.common.faultManageEdit': 'Operating privileges for fault libraries',
  'app.common.system': 'System',
  'app.common.userManage': 'Account',
  'app.common.maintOrderAuditConfig':
    'Approval Settings for Maintenance Orders',
  'app.common.userSpecialDutyRosterImport': 'Schedule Import',
  'app.common.userSpecialDutyRosterExport': 'Schedule Export',
  'app.common.permissionManage': 'Role',
  'app.common.phraseManage': 'Label',
  'app.common.systemSetting': 'Settings',
  'app.common.riskControlLog': 'Risk Log',
  'app.common.tool': 'Tools',
  'app.common.businessDataImport': 'Data Import',
  'app.common.maintOrderRecheckDispatchedImport': 'Pending review order import',
  'app.common.maintOrderRecheckDispatchedExport': 'Pending review order export',
  'app.common.anNengFittingModelMappingImport':
    'Anneng Shinway Fitting mapping import',
  'app.common.anNengFittingModelMappingExport':
    'Anneng Shinway Fitting mapping export',
  'app.common.businessDataExport': 'Data Export',
  'app.common.utilityTools': 'Utilities',
  'app.common.dataProcess': 'Data process',
  'app.common.productionDataProcess': 'Production data process',
  'app.common.revokeAfterQuoteAccepted': 'Revoke an approved maintenance',
  'app.common.revokeAfterQuoteAcceptedSnapshot':
    'Revoke an approved maintenance log',
  'app.common.stationBalanceDeduction':
    'Service station wallet balance deduction operation',
  'app.common.stationBalanceDeductionSnapshot':
    'Station Wallet Balance Deduction Log',
  'app.common.shenTongSettledVoucherSetting':
    'Shen Tong Settled Voucher Setting',
  'app.common.stationBatchMaintButter': 'Batch Maintenance Butter',
  'app.common.maintOrderViewAll': 'View all maintenance orders',
  'app.common.maintOrderReceive': 'Taking orders',
  'app.common.maintOrderAssign': 'Assign Employee',
  'app.common.maintOrderCheckin': 'Pickup',
  'app.common.maintOrderRecord': 'Record Order',
  'app.common.maintOrderQuote': 'Quote',
  'app.common.maintOrderRemarkCreate': 'Maintenance Order Remarks',
  'app.common.directSupplyFittingView': 'Fitting',
  'app.common.maintUncoveredCostInformAudit': 'Audit non-covered expenses',
  'app.common.vehicleGroupManage': 'Fleet',
  'app.common.vehicleTeam': 'Fleet',
  'app.common.more': 'More',
  'app.common.noData': 'No data',
  'app.common.selectFromTheLeftFirst': 'Select from the left first',
  'app.common.search': 'Search',
  'app.common.filter': 'Filter',
  'app.common.sort': 'Sort',
  'app.common.snapshotId': 'Snapshot',
  'app.common.close': 'Close',
  'app.common.expand': 'Expand',
  'app.common.repair': 'R',
  'app.common.inspect': 'C',
  'app.common.keep': 'M',
  'app.common.additionalDiapatch': 'A',
  'app.common.idle': 'L',
  'app.common.latestExportTime': 'Latest export time',
  'app.common.download': 'Download',
  'app.common.uploadPhotos': 'upload photos',
  'app.common.uploadFailedTryAgain': 'Upload failed, please try again',
  'app.common.item': 'Item',
  'app.common.loading': 'Loading',
  'app.common.DataExportingDescription':
    'The data is being exported, please come back to this page after a few minutes and click on the download button on the right side of the export to download the file.',
  'app.common.maintOrderNo': 'Order No',
  'app.common.stationAmercement': 'Fines',
  'app.common.report': 'Report',
  'app.common.vehicleInspectionReport': 'Inspection',
  'app.common.vehicleTeamMonthlyInspectionReport': 'Monthly inspection',

  'app.common.menuSalesOrders': 'Sales Orders',
  'app.common.menuPurchaseOrders': 'Purchase Orders',
  'app.common.menuVendor': 'Vendor',
  'app.common.menuProduct': 'Product',
  'app.common.menuInventory': 'Inventory',
  'app.common.menuCommission': 'Commission Setting',

  'app.common.appUploadAttachmentTypeRestriction':
    'The type of the uploaded file can only be of the following types {types}',
  'app.common.appUploadAttachmentSizeRestriction':
    'The size of the uploaded file cannot exceed {size}M.',
  'app.common.appUploadAttachmentFileRestriction':
    'Uploaded attachments cannot be the same file',
  'app.common.appUploadAttachmentFailedMsg': 'Attachment upload failed {msg}',
  'app.common.appUploadAction': 'Upload',
  'app.common.appUploadAttachmentFailedAgainMsg':
    'Attachment upload failed，please try again',
  'app.common.logout': 'Logout',
  'app.common.appUploadImageTypeRestriction':
    'The type of the uploaded image can only be of the following types {types}',
  'app.common.appUploadImageSizeRestriction':
    'The size of the uploaded image cannot exceed {size}M.',
  'app.common.appUploadImageFileRestriction':
    'The uploaded image cannot be the same image',
  'app.common.appUploadImageFailedMsg': 'Image upload failed: {msg}',
  'app.common.appUploadImageFailedAgainMsg':
    'Image upload failed, please try again',
  'app.common.appUploadImageQuantityRestriction':
    'The number of uploaded images cannot exceed {limitTotal}.',
  'app.common.fittingShoppingMall': 'Shopping Mall',
  'app.common.stationFittingShoppingMall': 'Station Shopping Mall',
  'app.common.stationFittingShoppingMallBlankTip':
    'Please check the content on the mobile client.',
};
