export default {
  'account.manage': '账号管理',
  'account.manage.basicInfo': '基本信息',
  'account.manage.otherAction': '其他操作',
  'account.manage.modifyPhone': '修改手机号',
  'account.manage.modifyPassword': '修改密码',
  'account.manage.bindAccount': '账号绑定',
  'account.manage.unbindWechatIcon': '未绑定微信图标',
  'account.manage.bindWechatIcon': '已绑定微信图标',
  'account.manage.tips':
    '绑定微信账号后可使用个人微信扫码登录电脑端，一键登录移动端。',
  'account.manage.onClickBind': '前往绑定',
  'account.manage.bindWechat': '已绑定微信',
  'account.manage.unbind': '解除绑定',
  'account.manage.confirmUnbind': '确认解除绑定吗？',
  'account.manage.oldPassword': '原密码',
  'account.manage.findPassword': '找回密码',
  'account.manage.newPassword': '新密码',
  'account.manage.modifyMobileNumber': '修改手机号',
  'account.manage.newMobileNumber': '新手机号',
  'account.manage.true.mobile.number': '正确的手机号',
  'account.manage.verificationCode': '验证码',
  'account.manage.trueVerificationCode': '正确的验证码',
  'account.manage.getVerificationCode': '获取验证码',
  'account.manage.newInfo': '新信息',
  'account.manage.inputMinLength8': '至少输入8位字符',
};
