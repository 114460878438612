export default {
  'stationMonthlyStatement.common.statement': '对账单',
  'stationMonthlyStatement.common.export': '导出',
  'stationMonthlyStatement.common.stationExportStatement': '服务站-导出对账单',
  'stationMonthlyStatement.common.confirmationDetailDownloadTitle':
    '请下载确认函并打印，盖上公章，邮寄到下方地址，如有问题，请及时联系渠道经理，谢谢。',
  'stationMonthlyStatement.common.downloadConfirmLetter': '下载确认函',
  'stationMonthlyStatement.common.uploadVoucher': '上传凭证',
  'stationMonthlyStatement.common.viewVoucher': '查看凭证',
  'stationMonthlyStatement.common.expressNumber': '快递单号',
  'stationMonthlyStatement.common.save': '保存',
  'stationMonthlyStatement.common.expressCompany': '快递公司',
  'stationMonthlyStatement.common.expressCompanyName': '快递公司名称',
  'stationMonthlyStatement.common.pleaseUploadVoucher': '请先上传凭证',
};
