export default {
  'user.manage.details.templateUserGroups': '{column}{userGroups}',
  'user.manage.details.belonging': '所属',
  'user.manage.details.locked': '锁定',
  'user.manage.details.unlock': '解锁',
  'user.manage.details.normal': '正常',
  'user.manage.details.dutyDate': '值班日期',
  'user.manage.details.dutyStatus': '值班状态',
  'user.manage.details.failedReason': '失败原因',
  'user.manage.details.schedulingTable': '排班表',
  'user.manage.details.schedule': '排班',
  'user.manage.details.exportRemark':
    '注意：&#10;1、标红字段为必填项。&#10;2、手机号需是系统内存在的11位数字。&#10;3、值班日期需按照“2020/1/1”或“2020-1-1”格式输入。',
  'user.manage.details.importFailedName': '排班表导入失败数据',
  'user.manage.details.lockTips': '账号已被锁定，无法登录系统',
  'user.manage.details.basicSetting': '基本设置',
  'user.manage.details.switchboardNumber': '分机号码',
  'user.manage.details.maintApprovalSetting': '维保单审批设置',
  'user.manage.details.assignedVehicleTeam': '指定车队',
  'user.manage.details.searchVehicleTeam': '搜索车队',
  'user.manage.details.accountRole': '账号角色',
  'user.manage.details.assignedRole': '已分配角色',
  'user.manage.details.accountPermissions': '账号权限',
};
