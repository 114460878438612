export default {
  'maint.details.overview': '概览',
  'maint.details.Uncovered': '保外',
  'maint.details.job': '作业',
  'maint.details.log': '日志',
  'maint.details.quotation': '报价',
  'maint.details.approver': '当前核准人',
  'maint.details.vehicleMaint': '车辆维保',
  'maint.details.maintOrderStatus': '状态',
  'maint.details.maintRequestType': '报修类型',
  'maint.details.maintRequestDate': '创建时间',
  'maint.details.completedDate': '完成时间',
  'maint.details.maintOrderNo': '维保单号',
  'maint.details.maintReservedAddress': '维修地点',
  'maint.details.reservedTime': '预约时间',
  'maint.details.reservedEndTime': '{dateTime}前',
  'maint.details.checkinOvertime': '接车超时',
  'maint.details.fittingComplianceTip':
    '请依据车队要求选择配件，未按照以下要求执行，单据一律不予审核通过。',

  // 保外费用
  'maint.details.vehicleTeamMaintUncoveredDetails': '车队保外报价明细',
  'maint.details.maintUncoveredConfirmRecord': '保外费用确认记录',
  'maint.details.feeItem': '费用项目',
  'maint.details.feePrice': '单价（{currencySymbol}）',
  'maint.details.workinghourNumberOrCount': '工时数/数量',
  'maint.details.feeUnit': '单位',
  'maint.details.stationQuotedAmount': '服务站报价（{currencySymbol}）',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmount':
    '车队保外报价（{currencySymbol}）',
  'maint.details.thirdPartyQuotedItemTitle': '外部名称',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmount':
    '原车队保外报价（{currencySymbol}）',
  'maint.details.modifyQuotationReasonTips':
    '原系统计算价：{systemCalculateAmount}，当前加价比例为{percentage}%。改价原因：{modifyQuotationReason}',
  'maint.details.maintUncoveredPipAutoCalculateTips':
    '此价格为按{maintUncoveredPip}%自动加价计算',
  'maint.details.vehicleTeamAgreementPriceTips': '此价格为车队协议价',
  'maint.details.stationQuotedAmountTotal': '服务站报价总计',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmountTotal':
    '车队保外报价总计',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmountTotal':
    '原车队保外报价总计',
  'maint.details.allowance': '补助',
  'maint.details.initiatedTime': '发起时间',
  'maint.details.initiator': '发起人',
  'maint.details.initiatedSnapshot': '快照（发起内容）',
  'maint.details.reviewer': '审核人',
  'maint.details.reviewedSnapshot': '快照（审核内容）',
  'maint.details.maintUncoveredRemark': '保外备注',
  'maint.details.maintUncoveredConfirmedDetails': '确认明细',
  'maint.details.maintUncoveredConfirmedVoucher': '确认凭证',
  'maint.details.maintUncoveredConfirmStatus': '状态',
  'maint.details.confirmedOrRevokedTime': '确认/作废时间',
  'maint.details.confirmedOrRevokedUser': '确认/作废人',
  'maint.details.viewVoucher': '查看凭证',

  // 报价信息
  'maint.details.stationQuoteDetails': '服务站报价信息',
  'maint.details.feeAmount': '金额（{currencySymbol}）',
  'maint.details.freedAmount': '减免金额（{currencySymbol}）',
  'maint.details.approvedResult': '核准结论',
  'maint.details.additionalDispatchTips':
    '补派原因：{additionalDispatchReason}。添加时间：{additionalDispatchTime}',
  'maint.details.referencePrice': '参考价格',
  'maint.details.exceedRatio': '超出比例',
  'maint.details.reason': '原因',
  'maint.details.workinghourFittingTotal': '工时配件合计',
  'maint.details.otherFeeTotal': '其他费用合计',
  'maint.details.freedFeeTotal': '减免总计',
  'maint.details.feeTotalTitle': '费用合计',
  'maint.details.approvePassed': '通过',
  'maint.details.approveRejected': '驳回',
};
