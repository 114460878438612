export default {
  'permission.manage.add.userGroupRemark': '说明',
  'permission.manage.add.userGroupName': '用户组名称',
  'permission.manage.add.permissionSettings': '权限设置',
  'permission.manage.add.pleaseEnterUserGroupName': '请输入用户组名称',
  'permission.manage.add.userGroupNamemaxLength': '用户组名称长度不得超过64位',
  'permission.manage.add.permissionKeysMinLength': '权限设置至少勾选一项',
  'permission.manage.add.updateUserGroup': '用户组信息已更新',
  'permission.manage.add.createUserGroup': '新用户组已创建',
  'permission.manage.add.editRole': '编辑角色',
  'permission.manage.add.addRole': '新增角色',
  'permission.manage.add.roleName': '角色名称',
};
