export default {
  'vehicleInspectionReport.components.reportStartTime': '报告开始时间',
  'vehicleInspectionReport.components.reportId': '报告 Id',
  'vehicleInspectionReport.components.inspectionType': '检车类型',
  'vehicleInspectionReport.components.inspectionDate': '检车日期',
  'vehicleInspectionReport.components.reportTime': '报告时间',
  'vehicleInspectionReport.components.inspectionStatistics': '检车统计',
  'vehicleInspectionReport.components.abnormals': '检车情况',
  'vehicleInspectionReport.components.inspectionItems': '检查项目',
  'vehicleInspectionReport.components.inspectionResult': '检查结果',
  'vehicleInspectionReport.components.inspectionMedia': '检查图片/视频',
  'vehicleInspectionReport.components.inspectionLocation': '检车地点',
};
