<script setup>
  import { reactive, watch, computed } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useI18n } from 'vue-i18n';
  import stringify from 'qs/lib/stringify';

  import { Dropdown as ADropdown } from 'ant-design-vue';
  import 'ant-design-vue/es/dropdown/style/index.js';

  import { ThunderboltTwoTone, RobotOutlined } from '@ant-design/icons-vue';

  import { useUserStore } from '@/stores/user.js';

  import useAgencyType from '@/compositions/use-agency-type.js';

  import { getAccessToken } from '@/utils/accessToken';
  import { isEmpty } from '@/utils/utils-validator';

  defineOptions({
    name: 'LayoutMenuTop',
  });

  const { t } = useI18n();
  const router = useRouter();
  const route = useRoute();
  const userStore = useUserStore();
  const { isPlatform } = useAgencyType();
  const state = reactive({
    visible: false, // 是否显示菜单
    menuActived: false, // 是否高亮菜单
  });

  watch(
    route,
    (currentRoute) => {
      if (userStore.oftenMenus && userStore.oftenMenus.length) {
        const selectedRegularMenu = userStore.oftenMenus.find(
          (item) => currentRoute.name === item.name,
        );
        if (selectedRegularMenu) {
          state.menuActived = false;
        } else {
          state.menuActived = userStore.menus.some((levelOne) => {
            return levelOne.children.some(
              (menu) => currentRoute.name === menu.name,
            );
          });
        }
      }
    },
    { immediate: true },
  );

  /**
   * 顶部菜单是否处于被访问状态
   * @param {Object} oftenMenu 顶部菜单路由
   * @param {Object} currentRoute 当前访问路由
   * @returns {boolean} true 表示当前处于被访问状态
   */
  function isActiveRegularMenu(oftenMenu, currentRoute) {
    return oftenMenu.name === currentRoute.name;
  }

  /**
   * 点击菜单
   * @param {Object} menu 路由菜单
   */
  function handleClickMenu(menu) {
    if (menu.name) {
      router.push({ name: menu.name });
    }
  }

  /**
   * 打开星威在途应用找站页面
   */
  function openSRSApplicationDashboard() {
    const urlParams = {
      token: getAccessToken(),
      redirect: '/dashboard',
    };
    const url = `${import.meta.env.VITE_SRS_APP_DOMAIN}/#/login?${stringify(
      urlParams,
    )}`;

    window.open(url, '_blank');
  }

  /**
   * 打开星威在途应用页面
   */
  function openSRSApplication() {
    const urlParams = {
      token: getAccessToken(),
    };
    const url = `${import.meta.env.VITE_SRS_APP_DOMAIN}/#/login?${stringify(
      urlParams,
    )}`;

    window.open(url, '_blank');
  }

  const hasFastGPTAppDomain = computed(
    () => !isEmpty(import.meta.env.VITE_FASTGPT_APP_DOMAIN),
  );
  /**
   * 打开 FastGPT 应用页面
   */
  function openFastGPTApplication() {
    window.open(import.meta.env.VITE_FASTGPT_APP_DOMAIN, '_blank');
  }
</script>

<template>
  <ul class="menu">
    <ADropdown v-model:visible="state.visible">
      <li class="menu-item menu-only">
        <span
          v-if="state.menuActived"
          class="actived-dot"
        />
        <span>{{ t('app.common.menu') }}</span>
      </li>
      <template #overlay>
        <div
          v-if="userStore.menus.length"
          class="sub-menu-wrapper"
        >
          <div
            v-for="(menu, menuIndex) in userStore.menus"
            :key="menuIndex"
            class="sub-menu"
          >
            <div class="title">
              {{ menu.meta.title }}
            </div>
            <ul
              v-if="menu.children"
              class="menus"
            >
              <li
                v-for="(subMenu, subMenuIndex) in menu.children"
                :key="subMenuIndex"
                class="sub-menu-item"
                @click="handleClickMenu(subMenu)"
              >
                <span>{{ subMenu.meta.title }}</span>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ADropdown>
    <li
      v-for="(oftenMenu, oftenMenuIndex) in userStore.oftenMenus"
      :key="oftenMenuIndex"
      class="menu-item"
      @click="handleClickMenu(oftenMenu)"
    >
      <span
        v-if="isActiveRegularMenu(oftenMenu, route)"
        class="actived-dot"
      />
      <span>{{ oftenMenu.meta.title }}</span>
    </li>
    <li
      v-if="isPlatform && hasFastGPTAppDomain"
      style="color: red"
      class="menu-item"
      @click="openFastGPTApplication"
    >
      <span>AI 快速代报修</span>
      <RobotOutlined />
    </li>
    <li
      v-if="isPlatform"
      style="color: red"
      class="menu-item"
      @click="openSRSApplicationDashboard"
    >
      <span>抢修快速找站</span>
      <ThunderboltTwoTone two-tone-color="red" />
    </li>
    <li
      v-if="isPlatform"
      style="color: red"
      class="menu-item"
      @click="openSRSApplication"
    >
      <span>星威在途1.0</span>
      <ThunderboltTwoTone two-tone-color="red" />
    </li>
  </ul>
</template>

<style lang="less" scoped>
  ul {
    margin-bottom: 0 !important;
  }

  .menu {
    display: flex;
    .menu-item {
      width: 120px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #1890ff;
      line-height: 22px;
      .actived-dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #1890ff;
        display: inline-block;
        margin-right: 6px;
      }
    }
    .menu-only {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .sub-menu-wrapper {
    margin-top: 4px;
    display: flex;
    flex-wrap: wrap;
    max-width: 636px;
    background-color: #fff;
    padding: 24px 18px;
    padding-top: 0;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
  .sub-menu {
    width: 200px;
    .title {
      padding: 0 6px;
      width: 70px;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
    }
    .menus {
      display: flex;
      max-width: 200px;
      flex-wrap: wrap;
    }
    .sub-menu-item {
      padding: 0 6px;
      padding-top: 12px;
      font-size: 14px;
      font-weight: 400;
      color: #1890ff;
      line-height: 22px;
      cursor: pointer;
      flex-basis: 50%;
    }
  }
</style>
