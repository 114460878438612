/**
 * utils-static-file.js
 * @file Static File 静态资源工具类
 */

const staticHost = import.meta.env.VITE_APP_STATIC_HOST;

/**
 * 获取静态资源文件URL
 * @param {string} filePath 静态资源文件路径（以 / 开头）
 * @returns 指定的静态资源文件URL
 */
export const getStaticFilePath = (filePath) => {
  return `${staticHost}${filePath}`;
};

/**
 * 获取 svg 类型的静态资源文件
 * @param {string} filename 静态资源文件名
 * @returns 指定的静态资源文件
 */
export const getStaticSvgFileUrl = (filename) => {
  const path = '/svg/';
  return `${staticHost}${path}${filename}`;
};

/**
 * 获取 xlsx 类型的静态资源文件
 * @param {string} filename 静态资源文件名
 * @returns {string} 指定的静态资源文件
 */
export const getStaticXlsxFileUrl = (filename) => {
  const path = '/xlsx/';
  return `${staticHost}${path}${filename}`;
};

/**
 * 通过文件名获取 html 类型的静态资源文件
 * @param {string} filename 静态资源文件名
 * @returns 指定的静态资源文件
 */
export const getStaticHtmlFileUrl = (filename) => {
  const staticHost = process.env.VUE_APP_STATIC_HOST;
  const secondHost = '/html/';
  return `${staticHost}${secondHost}${filename}`;
};
