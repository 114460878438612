export default {
  'stationMonthlyStatement.components.confirmedVoucherFilesLabel':
    '已盖章{textConfirmedVoucherFiles}的照片:',
  'stationMonthlyStatement.components.maxOtherVoucherFilesVerifyMessage':
    '{textOtherVoucherFiles}不能超过{maxOtherVoucherFiles}张',
  'stationMonthlyStatement.components.maxInvoiceVoucherFilesVerifyMessage':
    '{textInvoiceVoucherFiles}不能超过{maxInvoiceVoucherFiles}个',
  'stationMonthlyStatement.components.maxPhotoVerifyMessage':
    '已盖章{textConfirmedVoucherFiles}的照片不能超过{maxConfirmedVoucherFiles}张',
  'stationMonthlyStatement.components.settlementAmount': '结算金额（{unit}）',
  'stationMonthlyStatement.components.waitUnsettledAmount':
    '待结算金额（{unit}）',
  'stationMonthlyStatement.components.totalSettledAmount':
    '合计结算金额（{unit}）',
  'stationMonthlyStatement.components.carRepairAmount': '修车金额',
  'stationMonthlyStatement.components.fittingDiscountFee': '配件抵减',
  'stationMonthlyStatement.components.marginAmount': '保证金',
  'stationMonthlyStatement.components.amercement': '罚款',

  'stationMonthlyStatement.components.settlementSituation': '结算情况',
  'stationMonthlyStatement.components.estimatedSettlementDate': '预计结算日期',
  'stationMonthlyStatement.components.maintenanceSummary': '维修汇总',

  'stationMonthlyStatement.components.marginAmountStatus': '（保证金{label}）',

  'stationMonthlyStatement.components.repairTimes': '修车次数',
  'stationMonthlyStatement.components.otherFeeRemarkLabel': '其他费用备注',
  'stationMonthlyStatement.components.repairAmount': '修车金额（{unit}）',
  'stationMonthlyStatement.components.settledAmount': '已结算金额（{unit}）',
  'stationMonthlyStatement.components.settlementRatio': '结算比例',
  'stationMonthlyStatement.components.commissionAmount': '抽佣金额 ({unit})',
  'stationMonthlyStatement.components.unsettledAmount': '未结算金额（{unit}）',
  'stationMonthlyStatement.components.marginAmountDeduct':
    '应扣保证金金额（{unit}）',
  'stationMonthlyStatement.components.directSupplyFittingFee':
    '直供件采购费用（{unit}）',
  'stationMonthlyStatement.components.directSupplyFittingDiscountFee':
    '抵减直供件费用（{unit}）',
  'stationMonthlyStatement.components.directSupplyFittingDescription':
    '（上月结转：{historyAmount}，本月新增：{currentAmount}）',
  'stationMonthlyStatement.components.amercementFee': '罚款金额（{unit}）',
  'stationMonthlyStatement.components.amercementFeeDescription':
    '（上月结转：{historyAmount}，本月新增：{currentAmount}）',
  'stationMonthlyStatement.components.otherFee': '其他费用（{unit}）',
  'stationMonthlyStatement.components.finalSettleAmount': '实付金额（{unit}）',
  'stationMonthlyStatement.components.invoiceMessagePartOne':
    '请开具发票金额为',
  'stationMonthlyStatement.components.invoiceMessagePartTwo':
    '关于贵司采购的配件，如需开具发票，请联系渠道经理。',
  'stationMonthlyStatement.components.invoiceInfo': '开票信息',
  'stationMonthlyStatement.components.invoiceAmount': '开票金额（{unit}）',
  'stationMonthlyStatement.components.companyName': '公司名称',
  'stationMonthlyStatement.components.taxNumber': '税号',
  'stationMonthlyStatement.components.address': '地址',
  'stationMonthlyStatement.components.mobileNumber': '电话',
  'stationMonthlyStatement.components.depositBank': '开户银行',
  'stationMonthlyStatement.components.bankAccount': '银行账号',
  'stationMonthlyStatement.components.invoiceConfirmationMailingTitle':
    '发票及确认函邮寄信息',
  'stationMonthlyStatement.components.mailingAddress': '邮寄地址',
  'stationMonthlyStatement.components.addressee': '收件人',
  'stationMonthlyStatement.components.settledTime': '结算日期',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartOne':
    '您好，请仔细确认：',

  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartTwo':
    '  （一）截止{filterToTheLastDayOfCurrentMonth}，星威应结算的维修费用总金额为 {unit}{filterRepairFee}，没有未结算维修单据。',

  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartThree':
    '  （二）根据沟通，{filterToChineseDate}直供件采购金额 {unit}{filterDirectSupplyFittingFee}，应从维修费里抵减的直供件采购金额为{unit}{filterDirectSupplyFittingDiscountFee}。',

  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFour':
    '  （三）{filterToChineseDate}产生的罚款金额为 {unit}{filterAmercementFee}。',

  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartFive':
    '  （四）{filterToChineseDate}的实付金额为 {unit}{filterFinalSettleAmount}。',
  'stationMonthlyStatement.components.stationMonthlyStatementAlertNotSettlePartSix':
    ' 一旦确认，星威将不再承担并支付截止日之前在本结算单以外的维修费用。',
  'stationMonthlyStatement.components.stationMonthlyStatementStationBasicInfoTip':
    '星威财务收到已盖章的确认函和发票后更新',
  'stationMonthlyStatement.components.settlementVoucherTitle': '结算凭证',
  'stationMonthlyStatement.components.otherSettlementVoucher': '其他凭证',
  'stationMonthlyStatement.components.invoice': '发票',
  'stationMonthlyStatement.components.confirmationLetter': '确认函',
  'stationMonthlyStatement.components.confirm': '确认',
  'stationMonthlyStatement.components.statementTime': '对账单时间',
  'stationMonthlyStatement.components.channelManagerUser': '渠道经理',
  'stationMonthlyStatement.components.invoicingAbility': '开票能力',
  'stationMonthlyStatement.components.taxRate': '税率',
  'stationMonthlyStatement.components.uploadingSettlementSucceeded':
    '上传结算凭证成功',
  'stationMonthlyStatement.components.pleaseUpload': '请上传',
  'stationMonthlyStatement.components.textInvoiceVoucherFiles': '发票照片或PDF',
  'stationMonthlyStatement.components.contactPerson': '联系人',
  'stationMonthlyStatement.components.stationInformation': '服务站信息',
  'stationMonthlyStatement.components.stationName': '服务站名称',
};
