import { computed } from 'vue';

import { useUserStore } from '@/stores/user.js';

import { AgencyType } from '@/constants/option-value-agency.js';

export default function useAgencyType() {
  const userStore = useUserStore();
  const agencyType = userStore.userInfo?.agencyType;

  const isPlatform = computed(() => agencyType === AgencyType.PLATFORM);

  const isStation = computed(() => agencyType === AgencyType.STATION);

  const isVehicleTeam = computed(() => agencyType === AgencyType.VEHICLE_TEAM);

  return {
    isPlatform,
    isStation,
    isVehicleTeam,
  };
}
