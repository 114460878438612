export default {
  'vehicleInspectionReport.components.reportStartTime': 'Start Time',
  'vehicleInspectionReport.components.reportId': 'Report ID',
  'vehicleInspectionReport.components.inspectionType': 'Inspection Type',
  'vehicleInspectionReport.components.inspectionDate': 'Inspection Date',
  'vehicleInspectionReport.components.reportTime': 'Report Time',
  'vehicleInspectionReport.components.inspectionStatistics':
    'Inspection Statistics',
  'vehicleInspectionReport.components.abnormals': 'Abnormals',
  'vehicleInspectionReport.components.inspectionItems': 'Inspection Items',
  'vehicleInspectionReport.components.inspectionResult': 'Inspection Result',
  'vehicleInspectionReport.components.inspectionMedia':
    'Inspection Pictures/video',
  'vehicleInspectionReport.components.inspectionLocation':
    'Inspection Location',
};
