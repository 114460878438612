export default {
  'vehicle.team.user.addVehicleTeam': 'New group',
  'vehicle.team.user.vehiclesHaveBeenAllocated': '* assigned',
  'vehicle.team.user.unassignedGroup': '* unassigned team',
  'vehicle.team.user.relateVehicle': 'Associated Vehicles',
  'vehicle.team.user.deleteGroupContent':
    'After deleting a group, the vehicle will become unassigned, do you want to confirm the deletion?',
  'vehicle.team.user.vehicleTeam': 'Be Suitable Fleet group',
  'vehicle.team.user.vehicleGroupName': 'Group name',
  'vehicle.team.user.leaderUsers': 'Team Leader',
  'vehicle.team.user.pleaseSelectLeaderUsers': 'Please Choice Team Leader',
  'vehicle.team.user.vehicleGroupNameMaxLength':
    'The group name cannot exceed 128 characters.',
  'vehicle.team.user.vehicleGroupHasSave':
    'Team group information saved successfully',
  'vehicle.team.user.showDeletedGroups': 'Show deleted groups',
  'vehicle.team.user.recover': 'Recover',
  'vehicle.team.user.confirmRecovery': 'Confirm recovery?',
};
