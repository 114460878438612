import maintCommon from '@/locales/en-US/maint/maint-common.js';
import maintComponents from '@/locales/en-US/maint/maint-components.js';
import maintCompositions from '@/locales/en-US/maint/maint-compositions.js';
import maintDetails from '@/locales/en-US/maint/maint-details.js';
import maintConstants from '@/locales/en-US/maint/maint-constants.js';

export default {
  ...maintCommon,
  ...maintComponents,
  ...maintCompositions,
  ...maintDetails,
  ...maintConstants,
};
