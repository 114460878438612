/**
 * 导出默认通用配置
 */

import { getStaticFilePath } from '@/utils/utils-static-file.js';

export const setting = {
  // 检查系统更新的间隔毫秒数
  checkAppUpdateDelay: 1000 * 60,
  // 进行编译的依赖
  //  transpileDependencies: ['vue-echarts', 'resize-detector'],
  // 标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
  title: '星威养车',
  // 标题分隔符
  titleSeparator: ' - ',
  // 标题是否反转 如果为false:"page - title"，如果为ture:"title - page"
  titleReverse: false,
  // 路由模式，可选值为 history 或 hash
  routerMode: 'hash',
  // 不经过token校验的路由
  routesWhiteList: ['/login', '/reset-password', '/app-mapbox-choose', '/app-map-poimarker'],
  // 加载时显示文字
  loadingText: '正在加载中...',
  // token名称
  tokenName: 'authorization',
  // token在localStorage、sessionStorage、cookie存储的key的名称
  tokenTableName: 'authorization',
  // token存储位置localStorage sessionStorage cookie
  storage: 'localStorage',
  // 网站应用AppId
  appId: 'wx40067c4090917b43',
  // 视频默认封面图
  defaultVideoCoverImg: getStaticFilePath('/static/PC/fault-video-cover.png'),
};
