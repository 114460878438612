export default {
  'vehicle.team.manage': '车队管理',
  'vehicle.team.details.searchVehicleTeamName': '车队名称/车队ID',
  'vehicle.team.details.vehicleRepairRequirement': '配件要求',
  'vehicle.team.details.fittingName': '配件名称/配件ID',
  'vehicle.team.details.fittingNameToSelect': '请输入配件名称选择',
  'vehicle.team.details.fittingBrand': '配件品牌',
  'vehicle.team.details.fittingBrandToSelect': '请选择品牌',
  'vehicle.team.details.fittingModelName': '配件型号/英文名称/型号ID',
  'vehicle.team.details.vehicleModel': '适用车型',
  'vehicle.team.details.mustDirectSupply': '是否必须直供',
  'vehicle.team.details.vehicleModelToSelect': '请输入车型选择',
  'vehicle.team.details.teamGroup': '小组',
  'vehicle.team.details.maintUncoveredOfferPrice': '保外协议价',
  'vehicle.team.details.repairRequirement': '维修要求',
  'vehicle.team.details.repairApplicationApproval': '报修审批',
  'vehicle.team.details.repairAutoDispatch': '自动派单',
  'vehicle.team.details.repairAutoDispatch.open': '开启',
  'vehicle.team.details.repairAutoDispatch.close': '关闭',
  'vehicle.team.details.repairApplicationApprovalTip':
    '若开启则司机报修时需要车队长进行审批。',
  'vehicle.team.details.maintUncoveredQuotationConfirm': '保外确认',
  'vehicle.team.details.maintUncoveredQuotationConfirmTip':
    '若配置了开启或按条件开启，则保外费用需人工确认上传凭证。',
  'vehicle.team.details.accountInformation': '账号信息',
  'vehicle.team.details.accountNumber': '账号数',
  'vehicle.team.details.allAccountEnableStatus': '全部账号可用状态',
  'vehicle.team.details.superAdministrator': '超级管理员',
  'vehicle.team.details.disableRemark': '（禁用时，全部账号不可登录）',
  'vehicle.team.details.mobileNumberRemark': '（手机号将作为用户名使用）',
  'vehicle.team.details.passwordRemark': '（若不更改密码，则此项无需填写）',
  'vehicle.team.details.fittingVehicleModelExist':
    '该配件已对当前车型设置了品牌要求，请勿重复新增。',
  'vehicle.team.details.country': '所属国家',
  'vehicle.team.details.address': '地址',
  'vehicle.team.details.vehicleBusinessTypes': '承保类型',
  'vehicle.team.details.transportType': '运输类型',
  'vehicle.team.details.maintCoveredInfo': '承保信息',
  'vehicle.team.details.maintCoveredVehicleNum': '承保车辆数',
  'vehicle.team.details.expandInfo': '拓展信息',
  'vehicle.team.details.highSpeedProportion': '高速占比',
  'vehicle.team.details.maintCoverageRemark': '承保范围说明',
  'vehicle.team.details.workinghourName': '工时名称/工时ID',
  'vehicle.team.details.applicableVehicleAttrValue': '详细配置',
  'vehicle.team.details.projectType': '项目类型',
  'vehicle.team.details.projectName': '项目名称',
  'vehicle.team.details.vehicleTeamOfferPrice': '车队协议价',
  'vehicle.team.details.applicableVehicleGroups': '适用车队小组',
  'vehicle.team.details.applicableVehicleAttr': '适用车辆配置',
  'vehicle.team.details.applicableVehicleAttrValues': '适用详细配置',
  'vehicle.team.details.workinghourId': '工时ID',
  'vehicle.team.details.fittingId': '配件ID',
  'vehicle.team.details.fittingModelId': '型号ID',
  'vehicle.team.details.contractedStationMaintUncoveredPip': '签约站加价比例',
  'vehicle.team.details.cooperativeStationMaintUncoveredPip': '合作站加价比例',
  'vehicle.team.details.temporaryStationMaintUncoveredPip': '临时站加价比例',
  'vehicle.team.details.vehicleGroupNameAndStationType':
    '车队小组/服务站合作类型',
  'vehicle.team.details.maintUncoveredOfferPriceImport':
    '工时配件保外协议价导入',
  'vehicle.team.details.workinghourMaintUncoveredOfferPrice':
    '工时配件保外协议价',
  'vehicle.team.details.maintUncoveredOfferPriceImportFailData':
    '保外协议价导入失败数据',
  'vehicle.team.details.maintUncoveredPipSettings': '保外加价比例设置',
  'vehicle.team.details.maintUncoveredPip': '保外加价比例',
  'vehicle.team.details.maintUncoveredPipSettingsImport':
    '保外加价比例设置导入',
  'vehicle.team.details.maintUncoveredPipSettingsImportFailData':
    '保外加价比例导入失败数据',
};
