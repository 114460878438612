/**
 * utils-validator.js
 * @file 校验器工具类
 */
import { Comment, Fragment, Text } from 'vue';

/**
 * 判断是否为数字
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isNumericString(val) {
  return /^[0-9]*$/.test(val);
}

/**
 * 验证某字符串是否为最多保留两位小数的正数
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isTwoDecimalLimitNumber(val) {
  return /^[0-9]+(\.?|\.?[0-9]{1,2})$/.test(val) && Number(val) > 0;
}

/**
 * 验证某字符串是否为最多保留两位小数的正数或零
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isTwoDecimalLimitNumberOrZero(val) {
  return /^[0-9]+(\.?|\.?[0-9]{1,2})$/.test(val);
}

/**
 * 验证某字符串是否为最多保留一位小数的正数
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isOneDecimalLimitNumber(val) {
  return /^[0-9]+(\.?|\.?[0-9])$/.test(val) && Number(val) > 0;
}

/**
 * 验证某字符串是否为最多保留一位小数的正数或零
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isOneDecimalLimitNumberOrZero(val) {
  return /^[0-9]+(\.?|\.?[0-9])$/.test(val);
}

/**
 * 验证某字符串是否为最多保留两位小数的正负数和零
 * @param {string} val 需要验证的字符串
 */
export function isDecimalNumberWithTwoLimit(val) {
  return /^-?\d+(\.\d{1,2})?$/.test(val);
}

/**
 * 验证某字符串是否为正整数
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isPositiveIntegerNumber(val) {
  return /^([1-9]\d*)$/.test(val);
}

/**
 * 验证某字符串是否为整数
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isIntegerNumber(val) {
  return /^(0|[1-9][0-9]*|-[1-9][0-9]*)$/.test(val);
}

/**
 * 验证某字符串是否为自然数 [正整数][零]
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isNaturalNumber(val) {
  return /^(0|[1-9]\d*)$/.test(val);
}

/**
 * 验证某字符串是否为手机号
 * @param {string} val 需要验证的字符串
 * @returns {boolean} 验证结果
 */
export function isMobileNumber(val) {
  return /^(\d{1,24})$/.test(val);
}

/**
 * 验证某变量是否为空
 * @param {*} val 需要验证的变量
 * @returns {boolean} 验证结果
 */
export function isEmpty(val) {
  return (
    val === null ||
    typeof val === 'undefined' ||
    val === '' ||
    (Array.isArray(val) && val.length === 0)
  );
}

/**
 * 判断虚拟节点是否为空节点
 * @param {Object} vnode 虚拟节点对象
 * @returns {boolean} 验证结果
 */
export function isEmptyElement(vnode) {
  return (
    vnode &&
    (vnode.type === Comment ||
      (vnode.type === Fragment && vnode.children.length === 0) ||
      (vnode.type === Text && vnode.children.trim() === ''))
  );
}

/**
 * 判断插槽是否为空插槽
 * @param {Object[]} slot 插槽内容
 * @returns {boolean} 验证结果
 */
export function isEmptySlot(slot) {
  return !slot || slot().every(isEmptyElement);
}

/**
 * 判断传入值是否非空
 * undefined、null、空字符串、空数组、空对象返回false，其它返回true
 * @param {*} value 校验的值
 * @returns {boolean} 非空返回true，否则返回false
 * @example
 * isNotEmpty(undefined) // false
 * isNotEmpty(null) // false
 * isNotEmpty('') // false
 * isNotEmpty(0) // true
 * isNotEmpty([]) // false
 * isNotEmpty({}) // false
 * isNotEmpty(false) // true
 * isNotEmpty(true) // true
 */
export function isNotEmpty(value) {
  // undefined or null
  if (value == null) {
    return false;
  }
  // Array or string
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  }
  // Object
  if (Object.prototype.toString.call(value) === '[object Object]') {
    return Object.keys(value).length > 0;
  }
  return true;
}

/**
 * 判断是否最多n位小数的数字
 * @param {string} val
 * @param {number} decimal 小数位
 * @returns {boolean} 验证结果
 */
export function isMaxDecimalNumber(val = '', decimal = 2) {
  const reg = `^(([1-9]{1}\\d*)|(0{1}))(\\.\\d{0,${decimal}})?$`;
  return new RegExp(reg).test(String(val));
}

/**
 * 验证字符串是否为以 http 为协议的 URL
 * @param {string} val 待验证的字符串
 * @returns {boolean} 验证结果
 */
export function isUrlWithHttpProtocol(val) {
  const pattern = /^https?:\/\/[^\s/$.?#].[^\s]*$/i;
  return new RegExp(pattern).test(val);
}
