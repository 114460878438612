import router from '@/router';

export default {
  mounted(el, binding) {
    const { value } = binding;

    if (Array.isArray(value)) {
      if (value.length > 0) {
        const show = value.some(
          (type) => type === router.currentRoute.value.query.type,
        );
        if (!show) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error(`使用方式： v-form-control="['partner']"`);
    }
  },
};
