<script setup>
  import { onBeforeMount, onBeforeUnmount, computed } from 'vue';
  import { useI18n } from 'vue-i18n';

  import dayjs from 'dayjs';

  import { ConfigProvider as AConfigProvider } from 'ant-design-vue';

  import { useUserStore } from '@/stores/user.js';
  import { useSystemStore } from '@/stores/system.js';

  import { isEmpty } from '@/utils/utils-validator';

  import useLocale from '@/compositions/use-locale.js';

  defineOptions({
    name: 'AppRouter',
  });

  const { currentLocale } = useLocale();

  const { getLocaleMessage } = useI18n();

  const userStore = useUserStore();
  const systemStore = useSystemStore();

  /**
   * 用户空数据的默认配置
   * @param {*} text 当前列绑定的值
   */
  const transformCellText = ({ text }) => {
    return isEmpty(text) ? '-' : text;
  };

  /**
   * 获取当前应用语言所对应的 antd 组件库翻译
   */
  const currentLocaleAntd = computed(() => {
    const lang = currentLocale.value;
    return getLocaleMessage(lang).localeAntd;
  });

  /**
   * 获取当前应用语言所对应的 dayjs 插件翻译
   */
  const currentLocaleDayjs = computed(() => {
    const lang = currentLocale.value;
    return getLocaleMessage(lang).localeDayjs;
  });

  onBeforeMount(() => {
    // 加载 dayjs 对应的翻译
    dayjs.locale(currentLocaleDayjs.value);

    userStore.updateMenusAndUserInfo();
    systemStore.startCheckAppVersion();
  });

  onBeforeUnmount(() => {
    systemStore.endCheckAppVersion();
  });
</script>

<template>
  <AConfigProvider
    :locale="currentLocaleAntd"
    :auto-insert-space-in-button="false"
    :transform-cell-text="transformCellText"
  >
    <router-view />
  </AConfigProvider>
</template>
