export default {
  'maint.details.overview': 'Overview',
  'maint.details.Uncovered': 'Uncovered',
  'maint.details.job': 'Job',
  'maint.details.log': 'Log',
  'maint.details.quotation': 'Quotation',
  'maint.details.approver': 'Current Approver',
  'maint.details.vehicleMaint': 'ToB Orders',
  'maint.details.maintOrderStatus': 'Status',
  'maint.details.maintRequestType': 'Type of report',
  'maint.details.maintRequestDate': 'Creation time',
  'maint.details.completedDate': 'Completion time',
  'maint.details.maintOrderNo': 'Order No.',
  'maint.details.maintReservedAddress': 'Repair Location',
  'maint.details.reservedTime': 'Appointment time',
  'maint.details.reservedEndTime': '{dateTime}before',
  'maint.details.checkinOvertime': 'Pick-up timeout',
  'maint.details.fittingComplianceTip':
    'Please choose accessories according to the requirements of the team, if you do not follow the following requirements, the documents will not be approved.',

  // 保外费用
  'maint.details.vehicleTeamMaintUncoveredDetails': 'Uncovered Information',
  'maint.details.maintUncoveredConfirmRecord':
    'Confirmation of uncovered expenses',
  'maint.details.feeItem': 'Cost Item',
  'maint.details.thirdPartyQuotedItemTitle': 'External Name',
  'maint.details.feePrice': 'Unit Price({currencySymbol})',
  'maint.details.workinghourNumberOrCount': 'Numbers',
  'maint.details.feeUnit': 'Unit',
  'maint.details.stationQuotedAmount':
    'Service station quotation({currencySymbol})',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmount':
    'Fleet uncovered quotation({currencySymbol})',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmount':
    'Original Fleet uncovered quotation({currencySymbol})',
  'maint.details.modifyQuotationReasonTips':
    'Original Price：{systemCalculateAmount}, Increase price ratio to {percentage}%. Reason for price change：{modifyQuotationReason}',
  'maint.details.maintUncoveredPipAutoCalculateTips':
    'Price is based on {maintUncoveredPip}% Automatic markup calculation',
  'maint.details.vehicleTeamAgreementPriceTips': 'Fleet agreement price',
  'maint.details.stationQuotedAmountTotal': 'Total service station quotes',
  'maint.details.vehicleTeamMaintUncoveredQuotedAmountTotal':
    'Total Fleet Non-covered Quotes',
  'maint.details.originalVehicleTeamMaintUncoveredQuotedAmountTotal':
    'Original Total Fleet Non-covered Quotes',
  'maint.details.allowance': 'Allowance',
  'maint.details.initiatedTime': 'Initiation time',
  'maint.details.initiator': 'Initiator',
  'maint.details.initiatedSnapshot': 'Snapshot (initiating content)',
  'maint.details.reviewer': 'Reviewer',
  'maint.details.reviewedSnapshot': 'Snapshot (Audit content)',
  'maint.details.maintUncoveredRemark': 'Remark',
  'maint.details.maintUncoveredConfirmedDetails': 'Confirmation Details',
  'maint.details.maintUncoveredConfirmedVoucher': 'Voucher',
  'maint.details.maintUncoveredConfirmStatus': 'Status',
  'maint.details.confirmedOrRevokedTime': 'Confirmation Time',
  'maint.details.confirmedOrRevokedUser': 'Confirmation by',
  'maint.details.viewVoucher': 'View Voucher',

  // 报价信息
  'maint.details.stationQuoteDetails': 'Station Quote Information',
  'maint.details.feeAmount': 'Amount({currencySymbol})',
  'maint.details.freedAmount': 'Reduction Amount（{currencySymbol}）',
  'maint.details.approvedResult': 'Approved Conclusion',
  'maint.details.additionalDispatchTips':
    'Reason for additional dispatch: {additionalDispatchReason}. Add Time: {additionalDispatchTime}',
  'maint.details.referencePrice': 'Reference Price',
  'maint.details.exceedRatio': 'Excess Ratio',
  'maint.details.reason': 'Reason',
  'maint.details.workinghourFittingTotal':
    'Total working hours and accessories',
  'maint.details.otherFeeTotal': 'Total other fees',
  'maint.details.freedFeeTotal': 'Total reductions',
  'maint.details.feeTotalTitle': 'Total',
  'maint.details.approvePassed': 'Approve',
  'maint.details.approveRejected': 'Rejected',
};
