export default {
  'vehicle.team.add.vehicleTeamName': '车队名称',
  'vehicle.team.add.vehicleTeamReportName': '车队报告名称',
  'vehicle.team.add.vehicleTeamLogo': '车队logo',
  'vehicle.team.add.vehicleTeamAddress': '车队地址',
  'vehicle.team.add.detailedAddress': '详细地址',
  'vehicle.team.add.vehicleTeamDetailedAddress': '请输入车队详细地址',
  'vehicle.team.add.selectCountry': '请选择所属国家',
  'vehicle.team.add.vehicleTeamCreate': '车队信息创建成功',
  'vehicle.team.add.vehicleTeamEdit': '车队信息更新成功',
  'vehicle.team.add.editVehicleTeam': '编辑车队',
};
