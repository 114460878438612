export default {
  'stationMonthlyStatement.common.statement': 'Statement',
  'stationMonthlyStatement.common.export': 'Export',
  'stationMonthlyStatement.common.stationExportStatement':
    'Station-Export Statement',
  'stationMonthlyStatement.common.confirmationDetailDownloadTitle':
    'Please download the confirmation letter, print it, stamp it, and mail it to the address below. If you have any questions, please do not hesitate to contact the channel manager, thank you.',
  'stationMonthlyStatement.common.downloadConfirmLetter':
    'Download Confirmation Letter',
  'stationMonthlyStatement.common.uploadVoucher': 'Upload Voucher',
  'stationMonthlyStatement.common.viewVoucher': 'View Voucher',
  'stationMonthlyStatement.common.expressNumber': 'Courier Bill Number',
  'stationMonthlyStatement.common.save': 'Save',
  'stationMonthlyStatement.common.expressCompany': 'Courier Company',
  'stationMonthlyStatement.common.expressCompanyName': 'Courier Company Name',
  'stationMonthlyStatement.common.pleaseUploadVoucher':
    'Please upload proof first',
};
