import { createApp } from 'vue';
import { createPinia } from 'pinia';

import AppRouter from '@/app-router.vue';
import router from './router';
import i18n from '@/locales/locales.js';

import directives from './directives/index.js';

import './styles/index.less';
import './router/permissions';

createApp(AppRouter)
  .use(createPinia())
  .use(router)
  .use(directives)
  .use(i18n)
  .mount('#app');
