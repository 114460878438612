export default {
  'user.components.passwordTips':
    "(This item is not filled in if you don't change the password)",
  'user.components.usernameRegExp':
    'Combination of English and numbers, length between 7 and 16 characters.',
  'user.components.locations': 'locations',
  'user.components.basicInformation': 'Basic information',
  'user.components.assigningRoles': 'Assignment of roles',
  'user.components.pleaseEnterRoleName': 'Please enter the character name',
  'user.components.functionName': 'Function Name',
  'user.components.pleaseEnterFunctionName': 'Please enter the function name',
  'user.components.selectedRole': 'Selected roles',
  'user.components.viewPermission': 'View Permissions',
  'user.components.assigningVehicleGroup': 'Allocate vehicle fleet team',
  'user.components.fleetTeam': 'Vehicle fleet team',
  'user.components.AssignUserTeamLeader':
    'Assign the user to the vehicle fleet team as a car leader (optional)',
};
