export default {
  'stationAmercement.components.maintOrderNo': 'Order No.',
  'stationAmercement.components.amerceCreatedTime': 'Time of fine payment',
  'stationAmercement.components.amerceAmount': 'Penalty Amount',
  'stationAmercement.components.deductedAmount': 'Offset fine',
  'stationAmercement.components.remainingAmerceAmount':
    'Remaining Penalty',
  'stationAmercement.components.amerceStatus': 'Status',
  'stationAmercement.components.image': 'Picture',
  'stationAmercement.components.preview': 'Preview',
  'stationAmercement.components.amercementReason': 'Reason for fine',
  'stationAmercement.components.stationAmercementBillingTotal':
    'Total {total} entries',
};
