/**
 * @file 本地存储键定义
 */

export default {
  /**
   * 服务站管理列表搜索条件
   * @param {string} userId 用户 Id
   * @returns {string}
   */
  searchStation: (userId) => `search:${userId}:station`,

  /**
   * 车队管理列表搜索条件
   * @param {string} userId 用户 Id
   * @returns {string}
   */
  searchVehicleTeam: (userId) => `search:${userId}:vehicle-team`,

  /**
   * 分波点管理列表搜索条件
   * @param {string} userId 用户 Id
   * @returns {string}
   */
  searchDistributionCenter: (userId) => `search:${userId}:distribution-center`, // 分拨点

  /**
   * 站点地图线索记录筛选框搜索条件
   * @param {string} userId 用户 Id
   * @returns {string}
   */
  searchSiteMapClueList: (userId) => `search:${userId}:site-map-clue-list`, 

  /**
   * 应用语言环境
   */
  APP_LANGUAGE: 'app:language',

  /**
   * 应用版本
   */
  APP_VERSION: 'app:version',

  /**
   * 维保单搜索条件
   */
  APP_SEARCH_MAINT_ORDER: 'app:search:maintOrder',

  /**
   * 维保单排序条件
   */
  APP_SORT_MAINT_ORDER: 'app:sort:maintOrder',

  /**
   * 供应商管理列表搜索条件
   */
  APP_SEARCH_FITTING_SUPPLIER: 'app:search:fittingSupplier',
};
