import axios from 'axios';
import { debounce } from 'lodash-es';
import stringify from 'qs/lib/stringify';

import { message } from 'ant-design-vue';
import 'ant-design-vue/es/message/style/index.js';

import { useUserStore } from '@/stores/user.js';
import router from '@/router';
import i18n, { I18nLanguage } from '@/locales/locales.js';

import {
  contentType,
  requestTimeout,
  successCode,
  tokenName,
  messageDuration,
} from '@/config';

import { removeAccessToken } from '@/utils/accessToken';

import { RequestHeaderLocale } from '@/constants/option-value.js';

/**
 * 根据当前应用内设置的语言，获取不同类型的国际化请求头信息。
 * @param {string} currentLocale 当前应用内设置的语言
 * @param {string} 最终国际化请求头信息
 */
export function getRequestHeaderLocale(currentLocale) {
  switch (currentLocale) {
    case I18nLanguage.EN_US:
      return RequestHeaderLocale.EN_US;

    case I18nLanguage.ZH_CN:
      return RequestHeaderLocale.ZH_CN;

    default:
      return RequestHeaderLocale.ZH_CN;
  }
}

// loading对象
let loading = null;
// 当前正在请求的数量
let loadingRequestCount = 0;

// 将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题
const toHideLoading = debounce(
  () => {
    loading();
    loading = null;
  },
  300,
  { leading: true },
);

// 显示loading
function showLoading() {
  if (loadingRequestCount === 0 && !loading) {
    loading = message.loading(`${i18n.global.t('app.common.loading')}...`, 0);
  }
  loadingRequestCount = loadingRequestCount + 1;
}

// 隐藏loading
function hideLoading() {
  loadingRequestCount = loadingRequestCount - 1;
  loadingRequestCount = Math.max(loadingRequestCount, 0);
  if (loadingRequestCount === 0 && loading) {
    // 关闭loading
    toHideLoading();
  }
}

/**
 * 处理code异常
 * @param {number} code 错误码
 * @param {string} msg 错误信息
 * @param {boolean} hideError 是否隐藏错误信息
 */
const handleCode = (code = '', msg, hideError) => {
  let defaultErrorMsg = '';
  let routerPath = null;
  let routerType = 'push';
  switch (code) {
    case 400:
      defaultErrorMsg = '请求参数异常';
      break;
    case 401:
      defaultErrorMsg = '登录已过期，请重新登录';
      routerPath = '/login';
      routerType = 'replace';
      break;
    case 403:
      defaultErrorMsg = '您没有当前页面的访问权限';
      routerPath = '/403';
      break;
    case 500:
    default:
      defaultErrorMsg = '服务器异常，请联系技术支持。';
      break;
  }
  if (!hideError) {
    message.error({
      key: msg || defaultErrorMsg,
      content: msg || defaultErrorMsg,
      duration: messageDuration,
    });
  }
  if (routerPath) {
    router[routerType]({ path: routerPath }).catch(() => {});
  }
};

/**
 * axios初始化
 */
const instance = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
});

/**
 * axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    config.headers.locale = getRequestHeaderLocale(i18n.global.locale.value);

    config.headers['X-Request-ID'] = String(new Date().getTime()).slice(-7);
    // 取消get缓存
    if (/get/i.test(config.method)) {
      config.params = config.params || {};
      config.params.t = Date.parse(new Date());
    }

    const userStore = useUserStore();
    if (userStore.accessToken) {
      config.headers[tokenName] = userStore.accessToken;
    }
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = stringify(config.data);
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  },
);

/**
 * axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      hideLoading();
      const { data, config } = response;
      const { businessCode, message } = data;
      // console.log('response', response)
      //  操作正常Code数组
      const codeVerificationArray = Array.isArray(successCode)
        ? [...successCode]
        : [...[successCode]];
      //  是否操作正常
      if (codeVerificationArray.includes(businessCode.code)) {
        resolve(data);
      } else {
        handleCode(businessCode, message, config.headers.hideError);
        reject(
          '星威养车请求异常拦截:' +
            JSON.stringify({ url: config.url, businessCode, message }) ||
            'Error',
        );
      }
    });
  },
  async (error) => {
    console.log('err', error);
    hideLoading();
    const { response, message: msg } = error;
    if (error.response && error.response.data) {
      const { status, data } = response;
      handleCode(
        status,
        data.businessCode ? data.businessCode.description : '' || msg,
      );
      return Promise.reject(error);
    } else {
      let { message: msg } = error;
      if (msg === 'Network Error') {
        message.error({
          content: '网络错误，请切换网络后重试',
          duration: messageDuration,
        });
      } else if (msg.includes('timeout')) {
        message.error({
          content: '后端接口请求超时',
          duration: messageDuration,
        });
      } else if (msg.includes('Request failed with status code')) {
        const code = msg.substr(msg.length - 3);
        if (code === '401') {
          removeAccessToken();
          router.replace({ path: '/login' });
        } else if (code === '500') {
          msg = `系统维护中，请稍后再试，或联系技术支持并提供ID：`;
          message.error({
            content: msg,
            duration: messageDuration,
          });
        } else {
          msg = `后端接口${code}异常`;
          message.error({
            content: msg,
            duration: messageDuration,
          });
        }
      } else {
        message.error({
          content: '后端接口未知异常',
          duration: messageDuration,
        });
      }
      return Promise.reject(error);
    }
  },
);

// =================新的请求实例=================
const http = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL,
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
});

http.interceptors.request.use(
  (config) => {
    config.headers.locale = getRequestHeaderLocale(i18n.global.locale.value);
    config.headers['Client-Type'] = 'Browser';
    config.headers['X-Request-ID'] = String(new Date().getTime()).slice(-7);
    // 取消get缓存
    if (/get/i.test(config.method)) {
      config.params = config.params || {};
      config.params.t = Date.parse(new Date());
    }
    const userStore = useUserStore();
    if (userStore.accessToken) {
      config.headers['token'] = userStore.accessToken;
    }
    if (
      config.data &&
      config.headers['Content-Type'] ===
        'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = stringify(config.data);
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    return config;
  },
  (error) => {
    hideLoading();
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  (response) => {
    return new Promise((resolve, reject) => {
      hideLoading();
      const {
        data: { code, data, message },
        config,
      } = response;
      //  操作正常Code数组
      const codeVerificationArray = Array.isArray(successCode)
        ? [...successCode]
        : [...[successCode]];
      //  是否操作正常
      if (codeVerificationArray.includes(code)) {
        resolve(data);
      } else {
        handleCode(code, message, config.headers.hideError);
        // 手动构造业务错误下错误对象，并提供响应相关结果
        const error = new Error(message);
        error.response = response;
        reject(error);
      }
    });
  },
  async (error) => {
    hideLoading();
    const { message: msg, response } = error;
    let code = null;
    let errorMsg = '';
    if (msg === 'Network Error') {
      errorMsg = '网络连接失败，请检查网络后重试';
    } else if (msg.includes('timeout')) {
      errorMsg = '后端接口请求超时';
    } else if (msg.includes('Request failed with status code')) {
      code = Number(msg.substr(msg.length - 3));
      errorMsg = response?.data?.message || response?.responseData?.message;
    }
    if (!response.config.headers.hideError) {
      handleCode(code, errorMsg);
    }
    return Promise.reject(error);
  },
);
// =================新的请求实例=================

export { http };

export default instance;
