export default {
  'account.manage': 'Account',
  'account.manage.basicInfo': 'Basic Information',
  'account.manage.otherAction': 'Other Operations',
  'account.manage.modifyPhone': 'Modify phone number',
  'account.manage.modifyPassword': 'Modify Password',
  'account.manage.bindAccount': 'Account Binding',
  'account.manage.unbindWechatIcon': 'Unbound WeChat icon',
  'account.manage.bindWechatIcon': 'Bound WeChat icon',
  'account.manage.tips':
    'After binding the WeChat account, you can use your personal WeChat to scan the code to log in the computer and one-click to log in the mobile.',
  'account.manage.onClickBind': 'Go to Binding',
  'account.manage.bindWechat': 'Bound WeChat',
  'account.manage.unbind': 'Unbind',
  'account.manage.confirmUnbind': 'Confirm unbinding?',
  'account.manage.oldPassword': 'Original Password',
  'account.manage.findPassword': 'Retrieve Password',
  'account.manage.newPassword': 'New Password',
  'account.manage.modifyMobileNumber': 'Change Phone Number',
  'account.manage.newMobileNumber': 'New phone number',
  'account.manage.true.mobile.number': 'Correct phone number',
  'account.manage.verificationCode': 'Verification Code',
  'account.manage.trueVerificationCode': 'Correct Verification Code',
  'account.manage.getVerificationCode': 'Get Verification Code',
  'account.manage.newInfo': 'New Message',
  'account.manage.inputMinLength8': 'Enter at least 8 characters',
};
