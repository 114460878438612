export default {
  'vehicleTeamMonthlyInspectionReport.components.templateItems':
    '{total} items',

  'vehicleTeamMonthlyInspectionReport.components.reportId': 'Report ID',
  'vehicleTeamMonthlyInspectionReport.components.reportTime': 'Report time',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesInspectedNo':
    'Vehicles inspected No.',
  'vehicleTeamMonthlyInspectionReport.components.inspectionServiceNo':
    'Inspection Service No.',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItemNo':
    'Inspection Item No.',
  'vehicleTeamMonthlyInspectionReport.components.abnormalItemNo':
    'Abnormal Item No.',
  'vehicleTeamMonthlyInspectionReport.components.inspectionDate':
    'Inspection time',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesWithAbnormalsNo':
    'Vehicles with abnormals No.',
  'vehicleTeamMonthlyInspectionReport.components.percentageOfAbnormalVehicles':
    'Percentage of abnormal vehicles',
  'vehicleTeamMonthlyInspectionReport.components.inspectionNo':
    'Inspection No.',
  'vehicleTeamMonthlyInspectionReport.components.mainHiddenAbnormals':
    'Main hidden abnormals',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItems':
    'Inspection items',
  'vehicleTeamMonthlyInspectionReport.components.abnormals': 'Abnormals',
  'vehicleTeamMonthlyInspectionReport.components.inspectionMedia':
    'Inspection Pictures/video',
  'vehicleTeamMonthlyInspectionReport.components.totalFaultItems': 'Total',
};
