export default {
  'vehicle.team.add.vehicleTeamName': 'Fleet Name',
  'vehicle.team.add.vehicleTeamReportName': 'Report Name',
  'vehicle.team.add.vehicleTeamLogo': 'Logo',
  'vehicle.team.add.vehicleTeamAddress': 'Fleet Address',
  'vehicle.team.add.detailedAddress': 'Detailed Address',
  'vehicle.team.add.vehicleTeamDetailedAddress': 'Please enter a fleet address',
  'vehicle.team.add.selectCountry': 'Please select a country',
  'vehicle.team.add.vehicleTeamCreate':
    'Fleet Information Created Successfully',
  'vehicle.team.add.vehicleTeamEdit': 'Update Fleet Information Successfully',
  'vehicle.team.add.editVehicleTeam': 'Edit Fleet',
};
