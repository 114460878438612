import { storage, tokenTableName } from '@/config';
import cookie from 'js-cookie';

export function getAccessToken() {
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.getItem(tokenTableName);
    } else if ('sessionStorage' === storage) {
      return sessionStorage.getItem(tokenTableName);
    } else if ('cookie' === storage) {
      return cookie.get(tokenTableName);
    } else {
      return localStorage.getItem(tokenTableName);
    }
  } else {
    return localStorage.getItem(tokenTableName);
  }
}

export function setAccessToken(accessToken) {
  if (storage) {
    if ('localStorage' === storage) {
      localStorage.setItem(tokenTableName, accessToken);
    } else if ('sessionStorage' === storage) {
      sessionStorage.setItem(tokenTableName, accessToken);
    } else if ('cookie' === storage) {
      cookie.set(tokenTableName, accessToken);
    } else {
      localStorage.setItem(tokenTableName, accessToken);
    }
  } else {
    localStorage.setItem(tokenTableName, accessToken);
  }
}

export function removeAccessToken() {
  if (storage) {
    if ('localStorage' === storage) {
      localStorage.removeItem(tokenTableName);
    } else if ('sessionStorage' === storage) {
      sessionStorage.clear();
    } else if ('cookie' === storage) {
      cookie.remove(tokenTableName);
    } else {
      localStorage.removeItem(tokenTableName);
    }
  } else {
    localStorage.removeItem(tokenTableName);
  }
}
