/**
 * utils-route.js
 * @file 路由相关工具类
 */

/**
 * 过滤出菜单路由
 * @param {Array} routes 静态路由树
 */
export function filterMenuRoutes(routes) {
  const menuTree = [];
  for (const route of routes) {
    if (route.meta && route.meta.isMenuRoute) {
      const menu = { ...route };

      if (Array.isArray(route.children) && route.children.length > 0) {
        menu.children = filterMenuRoutes(route.children);
      }

      menuTree.push(menu);
    } else {
      if (Array.isArray(route.children) && route.children.length > 0) {
        filterMenuRoutes(route.children).forEach((route) => {
          menuTree.push(route);
        });
      }
    }
  }

  return menuTree;
}

/**
 * 根据机构类型过滤菜单路由
 * @param {Array} allMenuRoutes 菜单路由
 * @param {string} agencyType 机构类型
 * @returns {Array}
 */
export function filterMenuRoutesByAgencyType(allMenuRoutes, agencyType) {
  return allMenuRoutes.filter((route) => {
    // 检查当前节点是否满足过滤条件
    if (
      route.meta.permissionKeyWithRoute &&
      route.meta.permissionKeyWithRoute.agencyTypes.includes(agencyType)
    ) {
      // 如果当前节点满足过滤条件，则返回该节点及其子节点
      if (route.children) {
        route.children = filterMenuRoutesByAgencyType(
          route.children,
          agencyType,
        );
      }
      return true;
    } else if (route.children) {
      // 如果当前节点不满足过滤条件但有子节点，则递归过滤子节点
      route.children = filterMenuRoutesByAgencyType(route.children, agencyType);
      // 如果过滤后的子节点不为空，则返回当前节点及其子节点
      return route.children.length > 0;
    }
    // 如果当前节点不满足过滤条件且没有子节点，则不返回该节点
    return false;
  });
}

/**
 * 过滤出指定菜单路由
 * @param {Array} allMenuRoutes 菜单路由
 * @param {string[]} routeKeys 指定菜单路由键值数组
 * @returns {Array}
 */
export function filterTargetMenuRoutes(allMenuRoutes, routeKeys) {
  return allMenuRoutes.filter((route) => {
    // 检查当前节点是否满足过滤条件
    if (
      route.meta?.permissionKeyWithRoute &&
      routeKeys.includes(route.meta.permissionKeyWithRoute.key)
    ) {
      // 如果当前节点满足过滤条件，则返回该节点及其子节点
      if (route.children) {
        route.children = filterTargetMenuRoutes(route.children, routeKeys);
      }
      return true;
    } else if (route.children) {
      // 如果当前节点不满足过滤条件但有子节点，则递归过滤子节点
      route.children = filterTargetMenuRoutes(route.children, routeKeys);
      // 如果过滤后的子节点不为空，则返回当前节点及其子节点
      return route.children.length > 0;
    }
    // 如果当前节点不满足过滤条件且没有子节点，则不返回该节点
    return false;
  });
}
