import { createI18n } from 'vue-i18n';

import messageEnUS from '@/locales/locales-en-US.js';
import messageZhCN from '@/locales/locales-zh-CN.js';

import datetimeFormatEnUS from '@/locales/datetime-format-en-US.js';
import datetimeFormatZhCN from '@/locales/datetime-format-zh-CN.js';

import LocalStorageKey from '@/constants/local-storage-key.js';

// 语言类型枚举
const I18nLanguage = {
  EN_US: 'en-US',
  ZH_CN: 'zh-CN',
};

// 设置的首选语言
const currentLocale =
  localStorage.getItem(LocalStorageKey.APP_LANGUAGE) ?? I18nLanguage.ZH_CN;

// 国际化配置
const i18nConfig = {
  // 首选语言
  locale: currentLocale,
  // 在首选语言缺少翻译时选择使用中文
  fallbackLocale: I18nLanguage.ZH_CN,
  // you must set `false`, to use Composition API
  legacy: false,
  messages: {
    [I18nLanguage.EN_US]: messageEnUS,
    [I18nLanguage.ZH_CN]: messageZhCN,
  },
  datetimeFormats: {
    [I18nLanguage.EN_US]: datetimeFormatEnUS,
    [I18nLanguage.ZH_CN]: datetimeFormatZhCN,
  },
};

const i18n = createI18n(i18nConfig);

export { I18nLanguage };

export default i18n;
