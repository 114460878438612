export default {
  'stationMonthlyStatementConfirmation.common.title':
    'Vehicle maintenance cost confirmation statement',
  'stationMonthlyStatementConfirmation.common.xingwei':
    'SHINING WAY TELENOLOGY SDN.BHD. :',
  'stationMonthlyStatementConfirmation.common.partOne':
    'The service station ({stationName}) confirms that as of November {statementEndDate}, the remaining unsettled amount of maintenance fees is {repairFeeUnsettled} {unit}, and there are no unsettled maintenance order.',
  'stationMonthlyStatementConfirmation.common.partTwo':
    'Your company will not be required to bear and pay for any maintenance fees incurred before the settlement date other than those listed in this settlement statement.',
  'stationMonthlyStatementConfirmation.common.partThree':
    'The service station hereby confirms the authenticity of the actual maintenance situation and expenses in the attached document.',
  'stationMonthlyStatementConfirmation.common.seal': 'Stamp',
  'stationMonthlyStatementConfirmation.common.annexI':
    'Annex I: B-to-B Settlement of Maintenance Orders',
  'stationMonthlyStatementConfirmation.common.amountTo': 'Total',
  'stationMonthlyStatementConfirmation.common.annexII':
    'Annex II: External import billing',
  'stationMonthlyStatementConfirmation.common.annexIII':
    'Annex III: B-to-B Settlement of Orders',
  'stationMonthlyStatementConfirmation.common.annexIV':
    'Annex IV: Fine Details',
  'stationMonthlyStatementConfirmation.common.annexV':
    'Annex V: External import deduct billing',
  'stationMonthlyStatementConfirmation.common.annexVI':
    'Annex VI：Direct supply parts inventory details',
  'stationMonthlyStatementConfirmation.common.annexVII':
    'Annex VII：Direct supply parts outbound inventory details (only includes "deduction of returns")',
  'stationMonthlyStatementConfirmation.common.settleEndDate':
    'Before{settleEndDate}',
  'stationMonthlyStatementConfirmation.common.payCycle': 'Payment cycle',
  'stationMonthlyStatementConfirmation.common.theAmountActuallyPaid':
    'The out-of-pocket amount',
  'stationMonthlyStatementConfirmation.common.amountPenalty': 'Amount of fine',
  'stationMonthlyStatementConfirmation.common.marginAmountDeduct':
    'Amount of security deposit to be withheld',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDiscountFee':
    'Reduction of the amount of accessories',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingFee':
    'Amount due from accessories',
  'stationMonthlyStatementConfirmation.common.repairFeeUnsettled':
    'Amount due for settlement',
  'stationMonthlyStatementConfirmation.common.settlementRate':
    'Settlement Ratio',
  'stationMonthlyStatementConfirmation.common.repairFeeSettled':
    'Settled Amount',
  'stationMonthlyStatementConfirmation.common.collect': 'Summary',
  'stationMonthlyStatementConfirmation.common.orderCompletionTime':
    'Order Completion Time',
  'stationMonthlyStatementConfirmation.common.orderNumber': 'Order Number',
  'stationMonthlyStatementConfirmation.common.approvalTime': 'Audit time',
  'stationMonthlyStatementConfirmation.common.maintOrderNo': 'Order No.',
  'stationMonthlyStatementConfirmation.common.amercementConfirmedTime':
    'Fine Confirmation Time',
  'stationMonthlyStatementConfirmation.common.amerceAmount': 'Penalty Amount',
  'stationMonthlyStatementConfirmation.common.historyAmercementFee':
    'Remaining fines from previous month',
  'stationMonthlyStatementConfirmation.common.fittingName': 'Accessory Name',
  'stationMonthlyStatementConfirmation.common.model': 'Model',
  'stationMonthlyStatementConfirmation.common.inventoryQuantity':
    'Inventory Quantity',
  'stationMonthlyStatementConfirmation.common.totalPurchasePrice':
    'Total purchase price',
  'stationMonthlyStatementConfirmation.common.unitPurchasePrice':
    'Unit purchase price',
  'stationMonthlyStatementConfirmation.common.whetherToDeduct':
    'Whether to deduct',
  'stationMonthlyStatementConfirmation.common.postingDate': 'Posting Date',

  'stationMonthlyStatementConfirmation.common.outboundQuantity':
    'Outbound Quantity',
  'stationMonthlyStatementConfirmation.common.outboundDate': 'Outbound Date',
  'stationMonthlyStatementConfirmation.common.externalMaintOrderNo':
    'External Maint Order No',
  'stationMonthlyStatementConfirmation.common.settleAmount': 'Settle Amount',
  'stationMonthlyStatementConfirmation.common.commissionAmount':
    'Commission Amount',
  'stationMonthlyStatementConfirmation.common.deductAmount': 'Deduct Amount',
  'stationMonthlyStatementConfirmation.common.deductRemark': 'Deduct Remark',

  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseTotalPrice':
    'Increase inventory purchase total price',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingIncreaseInventoryPurchaseExpenseOffsetTotalPrice':
    'Increase inventory purchase expense offset total price',
  'stationMonthlyStatementConfirmation.common.directSupplyFittingDecreaseInventoryTotalPrice':
    'Total price',
};
