export default {
  'vehicleTeamMonthlyInspectionReport.components.templateItems': '{total}项',

  'vehicleTeamMonthlyInspectionReport.components.reportId': '报告 Id',
  'vehicleTeamMonthlyInspectionReport.components.reportTime': '报告时间',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesInspectedNo':
    '检查车辆数',
  'vehicleTeamMonthlyInspectionReport.components.inspectionServiceNo':
    '检车服务次数',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItemNo':
    '检查项目数',
  'vehicleTeamMonthlyInspectionReport.components.abnormalItemNo': '异常项目数',
  'vehicleTeamMonthlyInspectionReport.components.inspectionDate': '检车时间',
  'vehicleTeamMonthlyInspectionReport.components.vehiclesWithAbnormalsNo':
    '有异常车辆数',
  'vehicleTeamMonthlyInspectionReport.components.percentageOfAbnormalVehicles':
    '异常车辆占比',
  'vehicleTeamMonthlyInspectionReport.components.inspectionNo': '检查次数',
  'vehicleTeamMonthlyInspectionReport.components.mainHiddenAbnormals':
    '主要隐患异常',
  'vehicleTeamMonthlyInspectionReport.components.inspectionItems': '检查项目',
  'vehicleTeamMonthlyInspectionReport.components.abnormals': '异常情况',
  'vehicleTeamMonthlyInspectionReport.components.inspectionMedia':
    '检查图片/视频',
  'vehicleTeamMonthlyInspectionReport.components.totalFaultItems': '总故障项',
};
