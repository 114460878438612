import { http } from '@/utils/request';

/**
 * 获取审批节点类型可选项列表
 */
export function getApprovalNodeTypeOptions() {
  return http({
    url: 'v2/phrase/maint-approval-node-type/options',
    method: 'GET',
  });
}

/**
 * 查询维保报价审批流程详情
 */
export function getApprovalProcessDetail() {
  return http({
    url: '/v2/maint/approval-process/detail',
    method: 'GET',
  });
}

/**
 * 编辑维保报价审批流程
 */
export function editApprovalProcesss(data) {
  return http({
    url: '/v2/maint/approval-process/edit',
    method: 'POST',
    data,
  });
}

/**
 * 获取维保报价审批流程信息（回显）
 */
export function getApprovalProcessInfo() {
  return http({
    url: '/v2/maint/approval-process/info',
    method: 'GET',
  });
}

/**
 * 模糊检索用户组列表
 */
export function getUserGroupOptions(keyword, queryType) {
  return http({
    url: `/v2/user/user-group/options`,
    method: 'GET',
    params: {
      keyword,
      queryType,
    },
  });
}

/**
 * 模糊检索车队小组列表
 */
export function getVehicleGroupOptions(groupName) {
  return http({
    url: `/v2/vehicle/group/options/by-group-name?groupName=${groupName}`,
    method: 'GET',
  });
}

/**
 * 模糊检索用户列表
 */
export function getUserListOptions(keyword) {
  return http({
    url: `/v2/user/user-list/options?keyword=${keyword}`,
    method: 'GET',
  });
}

/**
 * 获取高频进站规则详情
 */
export function highFrequencyMaintDetail() {
  return http({
    url: '/v2/setting/high-frequency-maint/detail',
    method: 'GET',
  });
}

/**
 * 更新高频进站规则
 * @param {Object} data 更新数据
 */
export function highFrequencyMaintUpsert(data) {
  return http({
    url: '/v2/setting/high-frequency-maint/upsert',
    method: 'POST',
    data,
  });
}

/**
 * 根据key更新业务参数value
 * @param key 配置参数key
 * @param value 配置参数value
 */
export function updateBusinessConfigByKey(data) {
  return http({
    url: '/nv2/business-config/update-value',
    method: 'POST',
    data,
  });
}

/**
 * 月度里程确认, 获取所有车队列表
 */
export function getAllVechileTeam() {
  return http({
    url: '/nv2/agency/list',
    method: 'GET',
  });
}

/**
 * 获取所有车队（+分页）
 */
export function getVechileTeamList(data) {
  return http({
    url: '/nv2/agency/list-page',
    method: 'POST',
    data,
  });
}

/**
 * 通过key查询获取车队信息
 */
export function getVechileTeamListByKeys(data) {
  return http({
    url: '/nv2/agency/get-list',
    method: 'POST',
    data,
  });
}

/**
 * 获取容差里程 v2
 */
export function getToleranceMileageLimit() {
  return http({
    url: '/nv2/business-config/tolerance-mileage-limit',
    method: 'GET',
  });
}

/**
 * 更新容差里程
 */
export function updateToleranceMileageLimit(value) {
  return http({
    url: `/nv2/business-config/tolerance-mileage-limit/${value}`,
    method: 'PATCH',
  });
}

/**
 * 查询月度里程确认车队列表
 */
export function getMonthlyMileageConfirmVehicleTeam() {
  return http({
    url: `/nv2/business-config/monthly-mileage-confirm-vehicle-team`,
    method: 'GET',
  });
}

/**
 * 更新月度里程确认车队列表
 */
export function updateMonthlyMileageConfirmVehicleTeam(data) {
  return http({
    url: `/nv2/business-config/monthly-mileage-confirm-vehicle-team`,
    method: 'PATCH',
    data,
  });
}

/**
 * 车辆服务人员-详情
 */
export function settingVehicleServiceStaffDetail() {
  return http({
    url: '/v2/setting/vehicle-service-staff/detail',
  });
}

/**
 * 查询维保终审分单占比
 */
export function maintApprovalDispatchWeightDetail(value) {
  return http({
    url: `/v2/maint/approval-dispatch-weight/${value}/detail`,
  });
}

/**
 * 查询维保终审分单权重
 */
export function maintApprovalDispatchWeightInfo(value) {
  return http({
    url: `/v2/maint/approval-dispatch-weight/${value}/info`,
  });
}

/**
 * 编辑终审人员分单权重
 */
export function maintApprovalDispatchWeightEdit(data) {
  return http({
    url: '/v2/maint/approval-dispatch-weight/edit',
    method: 'POST',
    data,
  });
}

/**
 * 获取罚款自动确认天数配置
 */
export function fetchAmercementAutoConfirmDays() {
  return http({
    url: '/nv2/business-config/amercement-auto-confirm-days',
    method: 'GET',
  });
}

/**
 * 获取罚款规则介绍链接
 */
export function fetchAmercementRuleIntroductions() {
  return http({
    url: '/nv2/business-config/amercement-rule-introductions',
    method: 'GET',
  });
}

/**
 * 编辑服务站罚款配置
 * @param {Object} data 罚款配置项
 */
export function settingAmercementConfig(data) {
  return http({
    url: '/nv2/business-config/amercement',
    method: 'PUT',
    data,
  });
}

/**
 * 查询维保单复核配置
 */
export function maintOrderRecheckSettingDetails() {
  return http({
    url: `/v2/maint/maint-order-recheck-setting/list`,
  });
}

/**
 * 维保单复核设置分类
 */
export function getRecheckCategoryOptions() {
  return http({
    url: '/v2/phrase/recheck-category/options',
    method: 'GET',
  });
}

export function upsertMaintOrderRecheckSetting(data) {
  return http({
    url: '/v2/maint/maint-order-recheck-setting/upsert',
    method: 'POST',
    data,
  });
}

/**
 * 获取所属国家可选项
 * @returns
 */
export function fetchCountryOptions() {
  return http({
    url: `/v2/phrase/country/options`,
    method: 'GET',
  });
}

export function getMaintOrderTimeLinessSetting() {
  return http({
    url: '/nv2/business-config/get/maint-order-time-liness-setting',
    method: 'GET',
  });
}

export function editMaintOrderTimeLinessSetting(data) {
  return http({
    url: '/nv2/business-config/edit/maint-order-time-liness-setting',
    method: 'POST',
    data,
  });
}

/**
 * 获取系统公告信息
 *
 * @async
 * @function fetchSystemSettingOfNotification
 * @returns {Promise<Object>}
 */
export function fetchSystemSettingOfNotification() {
  return http({
    url: '/v2/sys-notice/query',
    method: 'POST',
  });
}

/**
 * 更新系统公告信息
 *
 * @async
 * @function updateSystemSettingOfNotification
 * @param {Object} requestBody 请求体
 * @param {string} requestBody.beginTime 生效开始时间
 * @param {string} requestBody.content 内容
 * @param {string} requestBody.endTime 生效结束时间
 * @param {string} [requestBody.link] 链接
 * @param {string} requestBody.sysNoticeId 系统公告id，新增时不用传
 * @returns {Promise<Object>}
 */
export function updateSystemSettingOfNotification(requestBody) {
  return http({
    url: '/v2/sys-notice/edit',
    method: 'POST',
    data: requestBody,
  });
}

/**
 * 获取一条有效系统公告信息
 *
 * @async
 * @function fetchSystemSettingOfNotificationWithEffective
 * @returns {Promise<Object>}
 */
export function fetchSystemSettingOfNotificationWithEffective() {
  return http({
    url: '/v2/sys-notice/effective',
    method: 'POST',
  });
}

/**
 * 自动派单服务站分单比例
 * @returns
 */
export function getStationLevelRatioSetting() {
  return http({
    url: `/nv2/business-config/get/station-level-ratio-setting`,
    method: 'GET',
    headers: { showLoading: false },
  });
}

/**
 * 编辑自动派单服务站分单比例
 * @param {Array} data 自动派单分单比例数据
 * @returns
 */
export function editStationLevelRatioSetting(data) {
  return http({
    url: `/nv2/business-config/edit/station-level-ratio-setting`,
    method: 'POST',
    data,
  });
}

/**
 * 获取采销自动收货天数
 *
 * @async
 * @function fetchPurchaseSalesAutoConfirmReceiptDays
 * @returns
 */
export function fetchPurchaseSalesAutoConfirmReceiptDays() {
  return http({
    url: '/nv2/business-config/goods-auto-receipt-days',
    method: 'GET',
  });
}

/**
 * 编辑采销自动收货天数
 *
 * @async
 * @function editPurchaseSalesAutoConfirmReceiptDays
 * @returns
 */
export function editPurchaseSalesAutoConfirmReceiptDays(data) {
  return http({
    url: '/nv2/business-config/edit/goods-auto-receipt-days',
    method: 'POST',
    data,
  });
}

/**
 * 获取系统公告展示端选项
 */
export function getNoticeEffectiveScopeOptions(data) {
  return http({
    url: '/v2/phrase/notice-effective-scope/options',
    method: 'GET',
    data,
  });
}

/**
 * 获取服务经理
 */
export function getVehicleServiceStaffList() {
  return http({
    url: '/v2/setting/vehicle-service-staff/detail',
    method: 'GET',
  });
}

/**
 * 编辑服务经理
 */
export function updateVehicleServiceStaffList(data) {
  return http({
    url: '/v2/setting/vehicle-service-staff/edit',
    method: 'POST',
    data,
  });
}
