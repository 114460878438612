export default {
  'permission.manage.add.userGroupRemark': 'Description',
  'permission.manage.add.userGroupName': 'User Group Name',
  'permission.manage.add.permissionSettings': 'Permission Settings',
  'permission.manage.add.pleaseEnterUserGroupName':
    'Please enter a user group name.',
  'permission.manage.add.userGroupNamemaxLength':
    'The length of the user group name must not exceed 64 digits.',
  'permission.manage.add.permissionKeysMinLength':
    'At least one of the permission settings should be checked.',
  'permission.manage.add.updateUserGroup':
    'User group information has been updated',
  'permission.manage.add.createUserGroup': 'New user group has been created',
  'permission.manage.add.editRole': 'Edit Role',
  'permission.manage.add.addRole': 'New Role',
  'permission.manage.add.roleName': 'Role Name',
};
