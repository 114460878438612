export default {
  'vehicle.team.components.managementVehiclesNumber': 'Vehicle Count',
  'vehicle.team.components.maintAmountLt':
    'Maintenance category cost statistics',
  'vehicle.team.components.nonMaintAmountLt': 'Non-maintenance cost statistics',
  'vehicle.team.components.maintUncoveredAmountLt': 'Total non-covered Costs',
  'vehicle.team.components.maintUncoveredQuotationConfirmValidate':
    'Turned on by condition, at least one needs to be configured.',
  'vehicle.team.components.vehicleGroupName': 'Please enter a group name',
  'vehicle.team.components.leaderUserIds':
    'Please select at least one team leader',
  'vehicle.team.components.repairApprovalSettings':
    'Please select the type of report approval',
  'vehicle.team.components.repairAutoDispatch':
    'Please select the type of auto dispatch',
  'vehicle.team.components.conditionDesc': 'Condition Description',
  'vehicle.team.components.amountLimited': 'Amount Limit',
  'vehicle.team.components.confirmCondition':
    'Multiple conditions can be selected and configured, any one of the conditions will be met to start the confirmation process.',
};
