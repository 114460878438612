import { useUserStore } from '@/stores/user.js';

export default {
  mounted(el, binding) {
    const { value } = binding;
    const userStore = useUserStore();

    if (Array.isArray(value)) {
      const userRole = userStore.agencyType;
      if (value.length > 0) {
        const show = value.some((role) => userRole === role);
        if (!show) {
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
    } else {
      throw new Error(`使用方式： v-roles="['PLATFORM']"`);
    }
  },
};
