export default {
  'vehicle.team.components.managementVehiclesNumber': '在管车辆数',
  'vehicle.team.components.maintAmountLt': '保养类费用统计',
  'vehicle.team.components.nonMaintAmountLt': '非保养类费用统计',
  'vehicle.team.components.maintUncoveredAmountLt': '保外费用总计',
  'vehicle.team.components.maintUncoveredQuotationConfirmValidate':
    '按条件开启，需至少配置一项。',
  'vehicle.team.components.vehicleGroupName': '请输入小组名称',
  'vehicle.team.components.leaderUserIds': '请至少选择一位车队长',
  'vehicle.team.components.repairApprovalSettings': '请选择报修审批类型',
  'vehicle.team.components.repairAutoDispatch': '请选择自动派单类型',
  'vehicle.team.components.conditionDesc': '条件说明',
  'vehicle.team.components.amountLimited': '金额限制',
  'vehicle.team.components.confirmCondition':
    '可多选配置多个条件，任意一个条件满足则开启确认流程',
};
