import { http } from '@/utils/request';

/**
 * 获取 vms build 版本号
 * @returns {*}
 */
export function fetchVmsCurrentBuildVersion() {
  return http({
    url: '/v2/application/version',
    method: 'GET',
    headers: { showLoading: false },
  });
}

/**
 * 检查并添加系统引导记录（返回是否需要添加引导记录）
 * @param {Object} requestBody 请求体
 * @returns {boolean}
 */
export function checkAndSaveSysGuideRecord(requestBody) {
  return http({
    url: `/v2/sys-guide-record/check-and-save`,
    method: 'POST',
    data: requestBody,
  });
}



/**
 * 获取前端应用远程版本号
 */
export async function fetchApplicationRemoteVersion() {
  const response = await fetch('/current-version');
  if (!response.ok) {
    return Promise.reject('fetch current-version failed');
  }
  return await response.text();
}
