import preventReClick from './preventReClick';
import formControl from './formControl';
import permission from './permission';
import roles from './roles';

// 自定义指令
const directives = {
  preventReClick,
  formControl,
  permission,
  roles,
};

// 这种写法可以批量注册指令
export default {
  install(Vue) {
    Object.keys(directives).forEach((key) => {
      Vue.directive(key, directives[key]);
    });
  },
};
