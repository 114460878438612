export default {
  'maint.components.sample': '这是命名示例',
  'maint.components.vehicleInformation': '车辆信息',
  'maint.components.vehicleBrandlAbel': '品牌型号',
  'maint.components.vehicleVinLabel': 'VIN码',
  'maint.components.engineNumberLabel': '发动机号码',
  'maint.components.vehicleMileageItemLabel': '报修里程数',
  'maint.components.faultInformation': '故障信息',
  'maint.components.faultPhenomenonLabel': '故障现象',
  'maint.components.repairInformationLabel': '报修信息',
  'maint.components.repairTimeLabel': '报修时间',
  'maint.components.maintenanceLocationLabel': '维修地点',
  'maint.components.faultReporterLabel': '报修人',
  'maint.components.serviceManagerLabel': '服务经理',
  'maint.components.appointmentTimeLabel': '预约时间',
  'maint.components.fleetGroupLabel': '车队小组',
  'maint.components.alternateContactsLabel': '备用联系人',
  'maint.components.historicalMaintenance': '历史维保',
  'maint.components.maintOrderNo': '维保单号',
  'maint.components.jobContent': '作业内容',
  'maint.components.meterMileage': '仪表里程',
  'maint.components.completionTime': '完成时间',
  'maint.components.mileageAccrual': '累计里程',
  'maint.components.search.placeholder.maintOrderNo': '后5位或完整单号',
  'maint.components.search.placeholder.vehiclePlateNo': '后3位或完整号牌',
  'maint.components.search.placeholder.keywordNo': '按维保单号或车辆号牌号码查询',
  'maint.components.checkInVehicleMileageItemLabel': '接车里程数',
  'maint.components.checkInVehicleMileageImgKey': '接车里程图',
  'maint.components.clickToView': '点击查看',
  'maint.components.MARequirementsLabel': '维保配件要求',
  'maint.components.originalReceivingDiagram': '原接车图',
  'maint.components.repairLocationLabel': '报修地点',
  'maint.components.standardDispatcherLabel': '标准调度',
  'maint.components.picturePreview': '图片预览',
  'maint.components.checkinOvertime': '接车超时（原定{dateTime}前）',
  'maint.components.checkin.before': '前接车',
  'maint.components.faultName': '故障名称',
  'maint.components.faultClassification': '分类',
  'maint.components.typeOfWarranty': '承保类型',
  'maint.components.addTime': '添加时间',
  'maint.components.maintenanceInformation': '维修信息',
  'maint.components.accessoryName': '配件名称',
  'maint.components.number': '数量',
  'maint.components.maintenanceTimeLabel': '维修时间',
  'maint.components.estimatedPick-upTime': '预计接车时间',
  'maint.components.underWarranty': '保内',
  'maint.components.pitAdministratorLabel': '维修站管理员',
  'maint.components.dispatchNotesLabel': '派单备注',
  'maint.components.pick-upInformationLabel': '接车信息',
  'maint.components.job-picture': '作业图片',
  'maint.components.maintenanceWorkerLabel': '维修工',
  'maint.components.receivingDiagram': '接车图片',
  'maint.components.todoItems': '待办项目',
  'maint.components.workinghourName': '工时名称',
  'maint.components.workinghourNumber': '工时数',
  'maint.components.workinghourCategoryName': '分类',
  'maint.components.workingHourAllowance': '工时补助',
  'maint.components.fittingMaintCostCount': '配件数量',
  'maint.components.otherFees': '其他费用',
  'maint.components.freightForAccessories': '配件运费({currencySymbol})',
  'maint.components.towingFees': '拖车费用({currencySymbol})',
  'maint.components.venueUsageFee': '场地使用费({currencySymbol})',
  'maint.components.nighttimeSubsidyFee': '夜间补助费用({currencySymbol})',
  'maint.components.fare': '出车费({currencySymbol})',
  'maint.components.new.vehicle.reminder': '新车',
  'maint.components.new.vehicle.service.requirement': '查看服务要求',
  'maint.components.vehicleMileageImg': '里程图片',
};
