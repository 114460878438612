/**
 * option-value-agency.js
 * @file 机构枚举定义
 */

/**
 * 机构类型
 */
export const AgencyType = {
  PLATFORM: 'PLATFORM', // 总包
  STATION: 'STATION', // 服务站
  VEHICLE_TEAM: 'VEHICLE_TEAM', // 车队
};

/**
 * 机构属性名称
 */
export const AgencyClueType = {
  DISTRIBUTION_CENTER: 'DISTRIBUTION_CENTER', // 分拨点
  STATION: 'STATION', // 服务站
  VEHICLE_TEAM: 'VEHICLE_TEAM', // 车队
};
