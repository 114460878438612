export default {
  'vehicle.team.user.addVehicleTeam': '新增小组',
  'vehicle.team.user.vehiclesHaveBeenAllocated': '辆已分配',
  'vehicle.team.user.unassignedGroup': '辆未分配小组',
  'vehicle.team.user.relateVehicle': '关联车辆',
  'vehicle.team.user.deleteGroupContent':
    '删除小组后车辆会变为未分配状态，是否确认删除？',
  'vehicle.team.user.vehicleTeam': '适用车队小组',
  'vehicle.team.user.vehicleGroupName': '小组名称',
  'vehicle.team.user.leaderUsers': '车队长',
  'vehicle.team.user.pleaseSelectLeaderUsers': '请选择车队长',
  'vehicle.team.user.vehicleGroupNameMaxLength': '小组名称最长不超过128字',
  'vehicle.team.user.vehicleGroupHasSave': '车队小组信息保存成功',
  'vehicle.team.user.showDeletedGroups': '显示已删除的小组',
  'vehicle.team.user.recover': '恢复',
  'vehicle.team.user.confirmRecovery': '是否确认恢复？',
};
