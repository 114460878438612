import vehicleTeamCommon from '@/locales/en-US/vehicle-team/vehicle-team-common.js';
import vehicleTeamComponents from '@/locales/en-US/vehicle-team/vehicle-team-components.js';
import vehicleTeamAdd from '@/locales/en-US/vehicle-team/vehicle-team-add.js';
import vehicleTeamDetails from '@/locales/en-US/vehicle-team/vehicle-team-details.js';
import vehicleTeamUser from '@/locales/en-US/vehicle-team/vehicle-team-user.js';

export default {
  ...vehicleTeamCommon,
  ...vehicleTeamComponents,
  ...vehicleTeamAdd,
  ...vehicleTeamDetails,
  ...vehicleTeamUser,
};
