export default {
  // 报修类型
  'maint.constants.option.MaintRequestType.EMERGENCY_RESCUE': 'Roadside Assistance',
  'maint.constants.option.MaintRequestType.MAINT_RESERVATION': 'Service',
  'maint.constants.option.MaintRequestType.BATCH_IMPORT': 'Batch maintenance',
  'maint.constants.option.MaintRequestType.STATION_BATCH_MAINT': 'Batch maintenance',

  // 保外确认状态
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_REVIEW': 'pending review',
  'maint.constants.option.MaintUncoveredQuotationStatus.PENDING_CONFIRM': 'pending confirm',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED_NOT_UPLOADED_VOUCHER': 'confirmed not uploaded voucher',
  'maint.constants.option.MaintUncoveredQuotationStatus.CONFIRMED': 'confirmed',
  'maint.constants.option.MaintUncoveredQuotationStatus.REJECTED': 'rejected',
  'maint.constants.option.MaintUncoveredQuotationStatus.REVOKED': 'revoked',
}