import { h } from 'vue';
import { defineStore } from 'pinia';

import { Button as AButton, message as AMessage } from 'ant-design-vue';
import 'ant-design-vue/es/button/style/index.js';
import 'ant-design-vue/es/message/style/index.js';

import { isEmpty } from '@/utils/utils-validator';

import config from '@/config/index.js';

import LocalStorageKey from '@/constants/local-storage-key.js';

import { fetchApplicationRemoteVersion } from '@/api/common.js';

export const useSystemStore = defineStore('system', {
  state: () => {
    return {
      // 检查版本更新的定时器
      timerCheckAppVersion: null,
    };
  },

  getters: {
    /**
     * 检查版本更新的定时器是否存在，true 表示存在
     */
    hasTimerCheckAppVersion() {
      return !isEmpty(this.timerCheckAppVersion);
    },
  },

  actions: {
    /**
     * 检查应用更新
     * 应用依赖库的文件指纹不同则认为版本有更新
     */
    async checkApplicationVersion() {
      const localVersion = localStorage.getItem(LocalStorageKey.APP_VERSION);
      const remoteVersion = await fetchApplicationRemoteVersion();
      if (localVersion !== remoteVersion) {
        const APP_UPDATE_MESSAGE_KEY = 'system-update-notification';

        const hideAppUpdateMessage = AMessage.info({
          key: APP_UPDATE_MESSAGE_KEY,
          duration: 0,
          content: h('span', [
            h('span', '当前系统版本有更新，请点击'),
            h(
              AButton,
              {
                type: 'link',
                size: 'small',
                onClick: function () {
                  // 关闭弹窗
                  hideAppUpdateMessage();
                  // 更新本地缓存应用版本
                  window.localStorage.setItem(
                    LocalStorageKey.APP_VERSION,
                    remoteVersion,
                  );
                  // 刷新页面
                  window.location.reload();
                },
              },
              { default: () => '更新' },
            ),
          ]),
        });
      }
    },

    /**
     * 开始检查版本更新
     */
    startCheckAppVersion() {
      this.timerCheckAppVersion = window.setInterval(
        this.checkApplicationVersion,
        config.checkAppUpdateDelay,
      );
    },

    /**
     * 关闭检查版本更新
     */
    endCheckAppVersion() {
      if (this.hasTimerCheckAppVersion) {
        window.clearInterval(this.timerCheckAppVersion);
        this.timerCheckAppVersion = null;
      }
    },
  },
});
