<script setup>
  import { useRouter } from 'vue-router';

  import AppLogoShinwellMinor from '@/components/app-logo-shinwell-minor.vue';

  defineOptions({
    name: 'LayoutLogoShinwellTop',
  });

  const router = useRouter();

  /**
   * 点击图标回调钩子
   */
  const onClickToRouteHome = () => {
    // 导航至首页
    router.push({
      name: 'HomeRouter',
    });
  };
</script>

<template>
  <span
    class="layout-logo-shinwell-top"
    @click="onClickToRouteHome"
  >
    <AppLogoShinwellMinor />
  </span>
</template>

<style lang="less" scoped>
  .layout-logo-shinwell-top {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 92px;
    height: 64px;
    background-color: #1890ff;

    cursor: pointer;
  }
</style>
