export default {
  'stationAmercement.components.maintOrderNo': '维保单号',
  'stationAmercement.components.amerceCreatedTime': '罚款罚缴时间',
  'stationAmercement.components.amerceAmount': '扣罚金额',
  'stationAmercement.components.deductedAmount': '抵扣罚款',
  'stationAmercement.components.remainingAmerceAmount': '剩余罚款',
  'stationAmercement.components.amerceStatus': '状态',
  'stationAmercement.components.image': '图片',
  'stationAmercement.components.preview': '预览',
  'stationAmercement.components.amercementReason': '罚款原因',
};
