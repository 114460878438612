export default {
  'vehicle.team.manage': 'Fleet',
  'vehicle.team.details.searchVehicleTeamName': 'Fleet Name or ID',
  'vehicle.team.details.vehicleRepairRequirement': 'Accessory Request',
  'vehicle.team.details.fittingName': 'Accessory Name or ID',
  'vehicle.team.details.fittingNameToSelect':
    'Please enter a accessory name to select',
  'vehicle.team.details.fittingBrand': 'Accessory Brand',
  'vehicle.team.details.fittingBrandToSelect': 'Please select the brand',
  'vehicle.team.details.fittingModelName':
    'Accessory Model/English name/Model ID',
  'vehicle.team.details.vehicleModel': 'Applicable models',
  'vehicle.team.details.mustDirectSupply': 'Must direct supply',
  'vehicle.team.details.vehicleModelToSelect':
    'Please enter the model to select',
  'vehicle.team.details.teamGroup': 'Group',
  'vehicle.team.details.maintUncoveredOfferPrice':
    'Non-covered agreement price',
  'vehicle.team.details.repairRequirement': 'Repair Request',
  'vehicle.team.details.repairApplicationApproval': 'Repair Approval',
  'vehicle.team.details.repairAutoDispatch': 'Repair Auto Dispatch',
  'vehicle.team.details.repairAutoDispatch.open': 'Open',
  'vehicle.team.details.repairAutoDispatch.close': 'Close',
  'vehicle.team.details.repairApplicationApprovalTip':
    "If turned on, the captain of the vehicle is required to approve the driver's application for repair.",
  'vehicle.team.details.maintUncoveredQuotationConfirm':
    'Non-covered Confirmation',
  'vehicle.team.details.maintUncoveredQuotationConfirmTip':
    'If configured to be turned on or turned on by condition, the Non-covered charge requires manual confirmation to upload the voucher.',
  'vehicle.team.details.accountInformation': 'Account Information',
  'vehicle.team.details.accountNumber': 'Number of Accounts',
  'vehicle.team.details.allAccountEnableStatus': 'All Accounts Available',
  'vehicle.team.details.superAdministrator': 'Super Administrator',
  'vehicle.team.details.disableRemark':
    '(When disabled, all accounts are not available for login)',
  'vehicle.team.details.mobileNumberRemark':
    '(Phone number will be used as user name)',
  'vehicle.team.details.passwordRemark':
    '(If you do not change the password, this item is not required)',
  'vehicle.team.details.fittingVehicleModelExist':
    'This accessory has already set the brand requirement for the current model, please do not add it again.',
  'vehicle.team.details.country': 'Country',
  'vehicle.team.details.address': 'Address',
  'vehicle.team.details.vehicleBusinessTypes': 'Business Type',
  'vehicle.team.details.transportType': 'Shipping Type',
  'vehicle.team.details.maintCoveredInfo': 'Coverage Information',
  'vehicle.team.details.maintCoveredVehicleNum': 'Number of Vehicles Covered',
  'vehicle.team.details.expandInfo': 'Expansion Information',
  'vehicle.team.details.highSpeedProportion': 'Percentage of Highway',
  'vehicle.team.details.maintCoverageRemark': 'Description of Coverage',
  'vehicle.team.details.workinghourName': 'Work Hours or ID',
  'vehicle.team.details.applicableVehicleAttrValue': 'Detailed configuration',
  'vehicle.team.details.projectType': 'Project Type',
  'vehicle.team.details.projectName': 'Project Name',
  'vehicle.team.details.vehicleTeamOfferPrice': 'Fleet Agreement Price',
  'vehicle.team.details.applicableVehicleGroups': 'Applicable Fleet Group',
  'vehicle.team.details.applicableVehicleAttr':
    'Applicable Vehicle configuration',
  'vehicle.team.details.applicableVehicleAttrValues':
    'Applicable Detailed configuration',
  'vehicle.team.details.workinghourId': 'Work ID',
  'vehicle.team.details.fittingId': 'Accessory ID',
  'vehicle.team.details.fittingModelId': 'Model ID',
  'vehicle.team.details.contractedStationMaintUncoveredPip':
    'Contracted Station Increase Ratio',
  'vehicle.team.details.cooperativeStationMaintUncoveredPip':
    'Cooperative Station Increase Ratio',
  'vehicle.team.details.temporaryStationMaintUncoveredPip':
    'Temporary Station Increase Ratio',
  'vehicle.team.details.vehicleGroupNameAndStationType':
    'Fleet group/service station cooperation type',
  'vehicle.team.details.maintUncoveredOfferPriceImport':
    'Import of Work Hours and Accessory Non-covered Agreement Prices',
  'vehicle.team.details.workinghourMaintUncoveredOfferPrice':
    'Work Hours and Accessory Non-covered Agreement Prices',
  'vehicle.team.details.maintUncoveredOfferPriceImportFailData':
    'Failed import data for non-covered agreement price',
  'vehicle.team.details.maintUncoveredPipSettings':
    'Non-covered Increase Ratio Setting',
  'vehicle.team.details.maintUncoveredPip': 'Non-covered Increase Ratio',
  'vehicle.team.details.maintUncoveredPipSettingsImport':
    'Import of Non-covered Increase Ratio Setting',
  'vehicle.team.details.maintUncoveredPipSettingsImportFailData':
    'Failed to import Non-covered Increase Ratio',
};
